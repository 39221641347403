import { Tag } from 'antd';
import { type SelectProps } from 'antd';
import React from 'react';

type TagRender = SelectProps['tagRender'];

export const tagRender: TagRender = (props): React.ReactElement => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};
