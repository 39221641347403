import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, List } from 'antd';
import React, { useEffect, useState } from 'react';

import { Item } from './item';
import { type ISortableListItem } from './types';

export * from './types';

export function SortableList({
  records,
  id,
  saveCallback,
  ItemTemplate,
}: {
  records: ISortableListItem[];
  id: string;
  saveCallback: (items: ISortableListItem[]) => void;
  ItemTemplate: (attributes) => React.ReactElement;
}) {
  const [items, setItems] = useState<ISortableListItem[]>(records);
  const { setNodeRef } = useDroppable({ id });

  useEffect((): void => {
    const recordsSorted: ISortableListItem[] = records.sort(
      (a: ISortableListItem, b: ISortableListItem): number =>
        a.position - b.position,
    );

    setItems(recordsSorted);
  }, [records]);

  const containerStyle = { display: 'flex' };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((ii: ISortableListItem[]) => {
        const oldIndex: number = ii.findIndex(
          (i: ISortableListItem): boolean => i.id === active.id,
        );
        const newIndex: number = ii.findIndex(
          (i: ISortableListItem): boolean => i.id === over.id,
        );

        const itemsMoved: ISortableListItem[] = arrayMove(
          ii,
          oldIndex,
          newIndex,
        );

        return itemsMoved.map((item: ISortableListItem, index: number) => {
          return { ...item, position: index + 1 };
        });
      });
    }
  }

  const droppableStyle = {
    padding: '20px 10px',
    border: '1px solid red',
    borderRadius: '5px',
    minWidth: '100%',
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(e: DragEndEvent): void => handleDragEnd(e)}
      >
        <SortableContext
          id={id}
          items={items}
          strategy={verticalListSortingStrategy}
        >
          <div ref={setNodeRef} style={droppableStyle}>
            <List
              style={containerStyle}
              itemLayout="horizontal"
              dataSource={items}
              renderItem={(item: ISortableListItem): React.ReactElement => (
                <Item id={item.id} record={item} ItemTemplate={ItemTemplate} />
              )}
            />
          </div>
        </SortableContext>
      </DndContext>

      <Button
        onClick={(): void => {
          saveCallback(items);
        }}
      >
        Save
      </Button>
    </>
  );
}
