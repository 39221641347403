import { ReloadOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Divider,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { type Dispatch } from 'redux';

import LanguageButtons from '../components/language-buttons';
import PropertyCategories from '../components/property/categories';
import PropertyName from '../components/property/name';
import PropertyType from '../components/property/type';
import PropertyUnit from '../components/property/unit';
import PropertyValue from '../components/property/value';
import WarehouseProperty from '../components/property/warehouse-property';
import { type RootState } from '../reducers';
import { categoriesLoadAll } from '../reducers/categories/actions';
import { propertyLoad } from '../reducers/property/actions';
import { type IPropertyState } from '../reducers/property/types';

function PropertyContainer({
  dispatch,
  Property,
}: {
  dispatch: Dispatch;
  Property: IPropertyState;
}): React.ReactElement {
  const { propertyCode } = useParams();

  useEffect((): void => {
    if (propertyCode) {
      dispatch(propertyLoad(propertyCode));
      dispatch(categoriesLoadAll());
    }
  }, [dispatch, propertyCode]);

  if (Property.property === undefined) {
    return <Skeleton active />;
  }

  if (Property.property === null) {
    return <Alert message="Property not found" type="error" showIcon />;
  }

  return (
    <>
      <Typography.Title level={2}>{propertyCode}</Typography.Title>

      <Space size={16} align="start">
        <Tooltip title="Reload product">
          <Button
            size="large"
            color="primary"
            onClick={(): void => {
              if (!propertyCode) {
                return;
              }
              dispatch(propertyLoad(propertyCode));
            }}
            disabled={Property.inProcess}
          >
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Divider />

      <LanguageButtons />

      <Divider />

      <Row gutter={16}>
        <PropertyName />

        <PropertyUnit />

        <PropertyType />
      </Row>

      <Divider />

      <Row gutter={16}>
        <PropertyCategories />
      </Row>

      <Divider />

      <Row gutter={16}>
        <WarehouseProperty />

        <PropertyValue />
      </Row>
    </>
  );
}

const mapStateToProps = ({
  Property,
}: RootState): {
  Property: IPropertyState;
} => ({
  Property,
});

export default connect(mapStateToProps)(PropertyContainer);
