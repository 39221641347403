import { type IWarehouseProperty } from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { Alert } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type IDownloaderState } from '../../reducers/downloader/types';
import Link from '../link';
import Table from '../table';

interface DataType extends IWarehouseProperty {
  key: string;
}

function WarehousePropertyTable({
  Downloader,
}: {
  Downloader: IDownloaderState;
}): React.ReactElement {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    const dataSourceUE: DataType[] = [];
    const propertiesName: Set<string> = new Set<string>([]);
    const unitsName: Set<string> = new Set<string>([]);

    Downloader.warehouse.properties?.forEach(
      (property: IWarehouseProperty): void => {
        propertiesName.add(property.name);
        unitsName.add(property.unit || '');

        dataSourceUE.push({
          key: property.rawName,
          ...property,
        });
      },
    );

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          a.name.localeCompare(b.name),
        filterSearch: true,
        filters: [...propertiesName]
          .map((name: string) => ({
            text: name,
            value: name,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType): boolean =>
          record.name.toLowerCase().includes(value.toLocaleLowerCase()),
        render: (_text: string, record: DataType): React.ReactElement => {
          const rawNameEncoded: string = encodeURIComponent(record.rawName);

          return (
            <Link
              to={`/downloader/warehouse-property/${rawNameEncoded}`}
              openInNewTab
              label={record.name}
            />
          );
        },
        width: '40%',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        sorter: (a: DataType, b: DataType): number =>
          a.unit?.localeCompare(b.unit || '') || 0,
        filterSearch: true,
        filters: [...unitsName]
          .map((unit: string) => ({
            text: unit,
            value: unit,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType): boolean =>
          record.unit?.toLowerCase().includes(value.toLowerCase()) || false,
        width: '40%',
      },
    ];

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [Downloader.warehouse.properties, Downloader.updatedAt]);

  if (Downloader.warehouse.properties === null) {
    return (
      <Alert
        message="Error"
        description="Warehouse properties not found"
        type="error"
        showIcon
      />
    );
  }

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      isLoading={Downloader.inProcess}
    />
  );
}

const mapStateToProps = ({
  Downloader,
}: RootState): { Downloader: IDownloaderState } => ({ Downloader });

export default connect(mapStateToProps)(WarehousePropertyTable);
