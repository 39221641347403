import { EOrderStatus } from '@mahawi/eshop-common/dist/src/types';
import React from 'react';

import OrderLayout from '../../components/orders/order-layout';

function OrdersRefundedContainer(): React.ReactElement {
  return <OrderLayout orderStatusTypeCodes={[EOrderStatus.REFUNDED]} />;
}

export default OrdersRefundedContainer;
