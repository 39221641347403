import { EditOutlined } from '@ant-design/icons';
import { type RootState } from 'admin/react/reducers';
import { type ILanguageState } from 'admin/react/reducers/language/types';
import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { productAdd } from '../../reducers/products/actions';
import { type IProductsState } from '../../reducers/products/types';

interface IForm {
  name: string;
}

const onFinish = (
  { name }: IForm,
  productDownloadedUUID: string | undefined,
  Language: ILanguageState,
  dispatch: Dispatch,
): void => {
  if (!Language.languageType) {
    return;
  }

  dispatch(productAdd(name, Language.languageType.code, productDownloadedUUID));
};

function ProductAdd({
  dispatch,
  Products,
  productDownloadedUUID = undefined,
  Language,
}: {
  dispatch: Dispatch;
  Products: IProductsState;
  productDownloadedUUID?: string;
  Language: ILanguageState;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  return (
    <Form
      form={form}
      onFinish={(values) =>
        onFinish(values, productDownloadedUUID, Language, dispatch)
      }
    >
      <Row gutter={[0, 24]}>
        <Col span={8} offset={8} className="gutter-row">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
              {
                type: 'string',
                min: 3,
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Create new product with name"
              prefix={<EditOutlined />}
              type="string"
              disabled={Products.inProcess}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = ({ Products, Language }: RootState) => ({
  Products,
  Language,
});

export default connect(mapStateToProps)(ProductAdd);
