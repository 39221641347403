import { Button, Col, Divider, Form, Input, Row, Space, Spin } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../../reducers';
import { updateUnit } from '../../reducers/property/actions';
import { type IPropertyState } from '../../reducers/property/types';
import BubbleComponent from '../bubble';

interface IForm {
  unit: string;
}

function PropertyUnit({
  dispatch,
  Property,
}: {
  dispatch: Dispatch;
  Property: IPropertyState;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  const onFinish = useCallback(
    ({ unit }: IForm): void => {
      if (Property.property) {
        dispatch(updateUnit(Property.property.code, unit));
      }
    },
    [dispatch, Property.property],
  );

  useEffect(() => {
    if (Property.property) {
      form.setFieldsValue({
        unit: Property.property.unit || '',
      });
    }
  }, [Property.property?.unit, form, Property.property]);

  return (
    <BubbleComponent span={8}>
      <Space size={16} align="start" direction="horizontal">
        <strong>Unit</strong>
      </Space>
      <Form form={form} onFinish={(values: IForm): void => onFinish(values)}>
        <Row>
          <Col span={8}>
            <Space size={16} align="start">
              <Button
                type="primary"
                htmlType="submit"
                disabled={Property.inProcess}
              >
                Submit
              </Button>
              <Spin size="small" spinning={Property.inProcess} />
            </Space>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={24}>
            <Form.Item name="unit" label="Unit">
              <Input placeholder="Unit" disabled={Property.inProcess} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BubbleComponent>
  );
}

const mapStateToProps = ({
  Property,
}: RootState): {
  Property: IPropertyState;
} => ({
  Property,
});

export default connect(mapStateToProps)(PropertyUnit);
