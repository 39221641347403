import { green, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  type ILanguageType,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { Button, Col, Divider, Form, Input, Row, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import {
  AddFocusEventListener,
  FormTextHandler,
} from '../../fragments/form-events';
import { type RootState } from '../../reducers';
import { type ILanguageState } from '../../reducers/language/types';
import { nameLive, nameUpdate } from '../../reducers/property/actions';
import { type IPropertyState } from '../../reducers/property/types';
import BubbleComponent from '../bubble';
import GoogleTranslate from '../google-translate';
import GoogleTranslateAutomat, {
  type ITextTranslate,
} from '../google-translate-automat';

interface IForm {
  name: string;
}

function PropertyName({
  dispatch,
  Language,
  Property,
}: {
  dispatch: Dispatch;
  Language: ILanguageState;
  Property: IPropertyState;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  const [gtLinkName, setGtLinkName] = useState<string>('GT name EN → ---');
  const [invalidLanguageTranslations, setInvalidLanguageTranslations] =
    useState<Set<string>>(new Set());
  const [propertyNameEN, setPropertyNameEN] = useState<ITranslation>();

  const translateName: () => void = useCallback((): void => {
    AddFocusEventListener(form, (text: string): void => {
      form.setFieldsValue({
        name: text,
      });
      if (Property.property?.code && Language.languageType?.code) {
        dispatch(
          nameLive(Property.property.code, text, Language.languageType.code),
        );
      }
    });
  }, [Language.languageType?.code, dispatch, form, Property.property]);

  const keyDownHandler: (event: KeyboardEvent) => void = useCallback(
    (event: KeyboardEvent): void => {
      FormTextHandler(form, event, (text: string): void => {
        if (Property.property?.code && Language.languageType?.code) {
          dispatch(
            nameLive(Property.property.code, text, Language.languageType.code),
          );
        }
      });
    },
    [Language.languageType?.code, dispatch, form, Property.property?.code],
  );

  const onFinish: (values: IForm) => void = useCallback(
    ({ name }: IForm): void => {
      if (Property.property && Language.languageType?.code) {
        dispatch(
          nameUpdate(Property.property.code, name, Language.languageType.code),
        );
      }
    },
    [dispatch, Property.property, Language.languageType?.code],
  );

  useEffect((): void => {
    const propertyNameENUE: ITranslation | undefined =
      Property.property?.names.find(
        ({ code }: ITranslation): boolean => code === 'EN',
      );
    setPropertyNameEN(propertyNameENUE);

    const propertyNameUE: string | null = getTranslation(
      Property.property?.names,
      Language.languageType,
    );

    const propertyNameTranslatedUE: string = propertyNameUE
      ? propertyNameUE
      : '';

    form.setFieldsValue({
      name: propertyNameTranslatedUE,
    });

    setGtLinkName(`GT name EN → ${Language.languageType?.code}`);
  }, [Language.languageType?.code, Property.property, form]);

  useEffect(() => {
    document.addEventListener('keydown', (event: KeyboardEvent): void =>
      keyDownHandler(event),
    );

    return (): void => {
      document.removeEventListener('keydown', (event: KeyboardEvent): void =>
        keyDownHandler(event),
      );
    };
  }, [Language, Language.languageType?.code, dispatch, form, keyDownHandler]);

  useEffect((): void => {
    const invalidLanguageTranslationsUE = new Set<string>();

    Language.languages?.forEach((language: ILanguageType): void => {
      const cn: ITranslation | undefined = Property.property?.names.find(
        (p: ITranslation): boolean => p.code === language.code,
      );

      if (!cn || cn.value.length <= 2) {
        invalidLanguageTranslationsUE.add(language.code);
      }
    });

    setInvalidLanguageTranslations(invalidLanguageTranslationsUE);
  }, [Language.languages, Language.languageType?.code, Property.property]);

  const propertyNameTranslated: string =
    propertyNameEN?.value || `** ${Property.property?.code} **`;

  const texts: ITextTranslate[] = [
    {
      text: propertyNameTranslated,
      callbacks: [
        (text: string, lc: string): void => {
          form.setFieldValue('name', text);

          if (Property.property?.code) {
            dispatch(nameLive(Property.property?.code, text, lc));
          }
        },
      ],
    },
  ];

  return (
    <BubbleComponent span={8}>
      <Space size={16} align="start" direction="horizontal">
        <strong>Name</strong>
        {invalidLanguageTranslations.size === 0 ? (
          <CheckCircleOutlined style={{ color: green.primary }} />
        ) : (
          <>
            <CloseCircleOutlined style={{ color: red.primary }} />
            <small>
              {[...invalidLanguageTranslations]
                .map((code: string): string => code)
                .join(', ')}
            </small>
          </>
        )}
      </Space>

      <Form form={form} onFinish={(values: IForm): void => onFinish(values)}>
        <Row>
          <Col span={8}>
            <Space size={16} align="start">
              <Button
                type="primary"
                htmlType="submit"
                disabled={Property.inProcess}
              >
                Submit
              </Button>
              <Spin size="small" spinning={Property.inProcess} />
            </Space>
          </Col>

          <Col span={8}>
            <GoogleTranslateAutomat
              texts={texts}
              submit={form.submit}
              isSaving={Property.inProcess}
            />
          </Col>

          <Col span={8}>
            <GoogleTranslate
              fromLanguageCode="EN"
              toLanguageCode={Language.languageType?.code}
              name={gtLinkName}
              text={propertyNameTranslated}
              onClick={(): void => translateName()}
            />
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
                {
                  type: 'string',
                  min: 2,
                },
              ]}
              label="Name"
            >
              <Input placeholder="Name" disabled={Property.inProcess} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BubbleComponent>
  );
}

const mapStateToProps = ({
  Language,
  Property,
}: RootState): {
  Language: ILanguageState;
  Property: IPropertyState;
} => ({
  Language,
  Property,
});

export default connect(mapStateToProps)(PropertyName);
