import { type IEbayMarketplace } from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { Collapse, Divider, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import {
  ebayCaregoryLoadAspects,
  ebayMarketplaceLoad,
  ebayMarketplaceLoadFulfillmentPolicies,
  ebayMarketplaceLoadPaymentPolicies,
  ebayMarketplaceLoadReturnPolicies,
  ebayMarketplacesLoad,
  ebayProductLoad,
} from '../../reducers/ebay/actions';
import { type IEbayState } from '../../reducers/ebay/types';
import { type IProductState } from '../../reducers/product/types';
import Aspect from './ebay/aspect';
import Inventories from './ebay/inventories';
import Marketplace from './ebay/marketplace';
import Offers from './ebay/offers';

const { Panel } = Collapse;

function EbayProductContainer({
  dispatch,
  Ebay,
  Product,
}: {
  dispatch: Dispatch;
  Ebay: IEbayState;
  Product: IProductState;
}): React.ReactElement {
  const [marketplaces, setMarketplaces] = useState<IEbayMarketplace[]>([]);
  const [marketplaceElements, setMarketplaceElements] = useState<
    React.ReactElement[]
  >([]);
  const [aspectElements, setAspectElements] = useState<React.ReactElement[]>(
    [],
  );

  useEffect(() => {
    Ebay.marketplaces.forEach((marketplace) => {
      if (marketplace.isActive) {
        dispatch(ebayMarketplaceLoad(marketplace.code));
        dispatch(ebayMarketplaceLoadFulfillmentPolicies(marketplace.code));
        dispatch(ebayMarketplaceLoadPaymentPolicies(marketplace.code));
        dispatch(ebayMarketplaceLoadReturnPolicies(marketplace.code));
        dispatch(ebayProductLoad(Product.uuid, marketplace.code));
        dispatch(ebayCaregoryLoadAspects(Product.uuid, marketplace.code));
      }
    });
  }, [dispatch, Ebay.marketplacesTimestamp, Ebay.marketplaces, Product.uuid]);

  useEffect(() => {
    const marketplacesUE = Ebay.marketplaces
      .filter(({ isActive }) => isActive)
      .sort((a, b) => a.name.localeCompare(b.name));

    const marketplaceElementsUE = marketplacesUE.map((marketplace) => (
      <Marketplace Marketplace={marketplace} key={marketplace.code} />
    ));

    const aspectElementsUE = marketplacesUE.map((marketplace) => (
      <Aspect Marketplace={marketplace} key={marketplace.code} />
    ));

    setMarketplaces(marketplacesUE);
    setMarketplaceElements(marketplaceElementsUE);
    setAspectElements(aspectElementsUE);
  }, [Ebay.marketplacesTimestamp, Ebay.marketplaces]);

  return (
    <Collapse
      onChange={(keys) => {
        if (keys.includes('ebay-product')) {
          dispatch(ebayMarketplacesLoad());
        }
      }}
    >
      <Panel header="Ebay" key="ebay-product">
        <Typography.Title level={3}>Marketplaces</Typography.Title>

        {marketplaceElements.map((marketplaceElement) => marketplaceElement)}

        <Divider />
        <Typography.Title level={3}>Aspects</Typography.Title>

        {aspectElements.map((aspectElement) => aspectElement)}

        <Divider />
        <Typography.Title level={3}>Inventory</Typography.Title>

        <Inventories Marketplaces={marketplaces} />

        <Divider />
        <Typography.Title level={3}>Offer</Typography.Title>

        <Offers Marketplaces={marketplaces} />
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Ebay, Product }: RootState) => ({ Ebay, Product });

export default connect(mapStateToProps)(EbayProductContainer);
