import {
  type IProductProperty,
  type IProperty,
  type IPropertyType,
} from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { PROPERTIES_ACTION } from './types';

export const propertyAdd = createAction(
  PROPERTIES_ACTION.ADD,
  (code: string) => ({
    payload: { code },
  }),
);

export const propertyAddResponse = createAction(
  PROPERTIES_ACTION.ADD_RESPONSE,
  (code: string) => ({
    payload: { property: { code } },
  }),
);

export const propertiesLoad = createAction(PROPERTIES_ACTION.LOAD);

export const propertiesLoadResponse = createAction(
  PROPERTIES_ACTION.LOAD_RESPONSE,
  (codes: string[]) => ({
    payload: { property: { codes } },
  }),
);

export const propertiesLoadAllTypes = createAction(
  PROPERTIES_ACTION.LOAD_ALL_TYPES,
);

export const propertiesLoadAllTypesResponse = createAction(
  PROPERTIES_ACTION.LOAD_ALL_TYPES_RESPONSE,
  (types: IPropertyType[]) => ({
    payload: { types },
  }),
);

export const loadProductProperties = createAction(
  PROPERTIES_ACTION.LOAD_PRODUCT_PROPERTIES,
  (productUUID: string) => ({
    payload: { product: { uuid: productUUID } },
  }),
);

export const loadProductPropertiesResponse = createAction(
  PROPERTIES_ACTION.LOAD_PRODUCT_PROPERTIES_RESPONSE,
  (properties: IProductProperty[]) => ({
    payload: { properties },
  }),
);

export const loadPropertiesByRawNames = createAction(
  PROPERTIES_ACTION.LOAD_BY_RAW_NAMES,
  (rawNames: string[]) => ({
    payload: { rawNames },
  }),
);

export const loadPropertiesByRawNamesResponse = createAction(
  PROPERTIES_ACTION.LOAD_BY_RAW_NAMES_RESPONSE,
  (properties: IProperty[]) => ({
    payload: { properties },
  }),
);
