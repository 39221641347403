import { Divider, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import WarehouseProductsTable from '../components/downloader/warehouse-products-table';
import WarehouseProductFilter from '../components/warehouse-product/filter';
import { type RootState } from '../reducers';
import { downloaderLoadWarehouseProducts } from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';

function DownloaderWarehouseProducts({
  Downloader,
}: {
  Downloader: IDownloaderState;
}): React.ReactElement {
  const [pageHeaderText, setPageHeaderText] = useState<string>(
    'Downloader warehouse products',
  );

  useEffect((): void => {
    if (Downloader.warehouse.products && Downloader.warehouse.products.length) {
      setPageHeaderText(
        `Downloader warehouse products (${Downloader.warehouse.products.length} items)`,
      );
    }
  }, [Downloader.warehouse.products]);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Typography.Title level={2}>{pageHeaderText}</Typography.Title>

      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <WarehouseProductFilter cb={downloaderLoadWarehouseProducts} />

        <Typography>
          {Downloader.updatedAt &&
            `Updated at ${dayjs(Downloader.updatedAt).fromNow()}`}
        </Typography>
      </Space>

      <WarehouseProductsTable />
    </Space>
  );
}

const mapStateToProps = ({
  Downloader,
}: RootState): { Downloader: IDownloaderState } => ({ Downloader });

export default connect(mapStateToProps)(DownloaderWarehouseProducts);
