import { GlobalOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../reducers';
import { ebayGetUserUrl, ebayUserCode } from '../reducers/ebay/actions';
import { type IEbayState } from '../reducers/ebay/types';

function EbayContainer({
  dispatch,
  Ebay,
}: {
  dispatch: Dispatch;
  Ebay: IEbayState;
}): React.ReactElement {
  const onFinishUrl = (values: { url: string }): void => {
    const url = new URL(values.url);

    const code = url.searchParams.get('code');

    if (code) {
      dispatch(ebayUserCode(code));
    }
  };

  if (Ebay.userUrl) {
    return (
      <Space size={16} align="start">
        <Tooltip title="Get URL for pair with account">
          <a
            href={Ebay.userUrl}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            <Button size="large" color="primary" disabled={Ebay.inProcess}>
              Go to ebay
            </Button>
          </a>
        </Tooltip>

        <Form onFinish={onFinishUrl}>
          <Form.Item name="url">
            <Input
              size="large"
              placeholder="Insert URL from ebay"
              prefix={<GlobalOutlined />}
            />
          </Form.Item>

          <Button
            size="large"
            color="primary"
            type="primary"
            htmlType="submit"
            disabled={Ebay.inProcess}
          >
            Save
          </Button>
        </Form>
      </Space>
    );
  }

  return (
    <Space size={16} align="start">
      <Tooltip title="Get URL for pair with account">
        <Button
          size="large"
          color="primary"
          onClick={() => {
            dispatch(ebayGetUserUrl());
          }}
          disabled={Ebay.inProcess}
        >
          Pair with ebay account
        </Button>
      </Tooltip>
    </Space>
  );
}

const mapStateToProps = ({ Ebay }: RootState): { Ebay: IEbayState } => ({
  Ebay,
});

export default connect(mapStateToProps)(EbayContainer);
