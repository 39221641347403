import { InfoCircleOutlined } from '@ant-design/icons';
import { type ILanguageType } from '@mahawi/eshop-common/dist/src/types';
import { List, Popover, Radio, Space, Tooltip, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../reducers';
import {
  nextLanguage,
  previousLanguage,
  setLanguage,
} from '../reducers/language/actions';
import { type ILanguageState } from '../reducers/language/types';

function LanguageButtons({
  Language,
  dispatch,
  languageTypeCodeError = [],
}: {
  Language: ILanguageState;
  dispatch: Dispatch;
  languageTypeCodeError?: string[];
}): React.ReactElement {
  const onChangeSelect = useCallback(
    (languageType: ILanguageType): void => {
      dispatch(setLanguage(languageType));
    },
    [dispatch],
  );

  const keyDownHandler = useCallback(
    (event: KeyboardEvent): void => {
      if (!['ą', 'ß'].includes(event.key)) {
        return;
      }

      // alt + s
      if (event.key === 'ß') {
        dispatch(nextLanguage());
      }

      // alt + a
      if (event.key === 'ą') {
        dispatch(previousLanguage());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const eventListener = (e: KeyboardEvent): void => keyDownHandler(e);

    document.addEventListener('keydown', eventListener);

    return (): void => {
      document.removeEventListener('keydown', eventListener);
    };
  }, [
    Language.languageType?.code,
    Language.languages,
    dispatch,
    keyDownHandler,
  ]);

  const buttons: React.ReactElement[] | undefined = Language.languages?.map(
    (language: ILanguageType): React.ReactElement => (
      <Tooltip title={language.name} key={language.code}>
        <Radio.Button
          value={language.code}
          style={{
            color: languageTypeCodeError.includes(language.code)
              ? 'red'
              : 'black',
          }}
          onClick={(): void => onChangeSelect(language)}
        >
          {language.code}
        </Radio.Button>
      </Tooltip>
    ),
  );

  const infoContent: React.ReactElement = (
    <List>
      <List.Item>
        <Typography.Text strong>option + s</Typography.Text> - next language
      </List.Item>
      <List.Item>
        <Typography.Text strong>option + a</Typography.Text> - previous language
      </List.Item>
    </List>
  );

  return (
    <Space size={16} align="start">
      <Radio.Group value={Language.languageType?.code}>{buttons}</Radio.Group>
      <Popover title="Shortcuts" trigger="hover" content={infoContent}>
        <InfoCircleOutlined />
      </Popover>
    </Space>
  );
}

const mapStateToProps = ({ Language }: RootState) => ({
  Language,
});

export default connect(mapStateToProps)(LanguageButtons);
