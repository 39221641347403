import { EPropertyType, type IPropertyType } from '@mahawi/eshop-common';
import { Button, Col, Divider, Form, Row, Select, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../../reducers';
import { type IPropertiesState } from '../../reducers/properties/types';
import { updateType } from '../../reducers/property/actions';
import { type IPropertyState } from '../../reducers/property/types';
import BubbleComponent from '../bubble';

interface IForm {
  type: EPropertyType;
}

function PropertyType({
  dispatch,
  Property,
  Properties,
}: {
  dispatch: Dispatch;
  Property: IPropertyState;
  Properties: IPropertiesState;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  const [propertyTypesElements, setPropertyTypesElements] = useState<
    React.ReactElement[]
  >([]);

  const onFinish = useCallback(
    ({ type }: IForm): void => {
      if (Property.property) {
        dispatch(updateType(Property.property.code, type));
      }
    },
    [dispatch, Property.property],
  );

  useEffect(() => {
    const propertyTypesElementsUE: React.ReactElement[] = Properties.types.map(
      (type: IPropertyType): React.ReactElement => (
        <Select.Option key={type.code} value={type.code}>
          {type.name}
        </Select.Option>
      ),
    );

    setPropertyTypesElements(propertyTypesElementsUE);
  }, [Properties.types]);

  useEffect(() => {
    if (Property.property) {
      form.setFieldsValue({
        type: Property.property.type,
      });
    }
  }, [form, Property.property?.type, Property.property, Properties.types]);

  return (
    <BubbleComponent span={8}>
      <Space size={16} align="start" direction="horizontal">
        <strong>Type</strong>
      </Space>
      <Form form={form} onFinish={(values: IForm): void => onFinish(values)}>
        <Row>
          <Col span={8}>
            <Space size={16} align="start">
              <Button
                type="primary"
                htmlType="submit"
                disabled={Property.inProcess}
              >
                Submit
              </Button>
              <Spin size="small" spinning={Property.inProcess} />
            </Space>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={24}>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                },
              ]}
              label="Type"
            >
              <Select size="large">{propertyTypesElements}</Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BubbleComponent>
  );
}

const mapStateToProps = ({
  Property,
  Properties,
}: RootState): {
  Property: IPropertyState;
  Properties: IPropertiesState;
} => ({
  Property,
  Properties,
});

export default connect(mapStateToProps)(PropertyType);
