import { EOrderStatus } from '@mahawi/eshop-common/dist/src/types';
import React from 'react';

import OrderLayout from '../../components/orders/order-layout';

function OrdersShippedContainer(): React.ReactElement {
  return <OrderLayout orderStatusTypeCodes={[EOrderStatus.SHIPPED]} />;
}

export default OrdersShippedContainer;
