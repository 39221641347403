import { green, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { type IBrand } from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { Button, Col, Collapse, Form, Input, Row, Select, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { getFilterOption, getFilterSort } from '../../fragments/select-filter';
import { brandsLoadAll } from '../../reducers/brand/actions';
import { type IBrandState, type ILoadAll } from '../../reducers/brand/types';
import { brandSet } from '../../reducers/product/actions';
import {
  type IBrandSet,
  type IProductState,
} from '../../reducers/product/types';
import CopyToClipboard from '../copy-to-clipboard';

interface IForm {
  brand: IBrand;
  mpn: string;
  gtin: string;
}

const { Panel } = Collapse;
const { Option } = Select;

function BrandAndIdentification({
  Product,
  Brand,
  dispatch,
}: {
  Product: IProductState;
  Brand: IBrandState;
  dispatch: Dispatch<IBrandSet | ILoadAll>;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  const [statusIcon, setStatusIcon] = useState<React.ReactElement>();

  const onFinish = useCallback(
    ({ brand, mpn, gtin }: IForm): void => {
      dispatch(brandSet(Product.uuid, brand, mpn, gtin));
    },
    [dispatch, Product.uuid],
  );

  useEffect((): void => {
    const statusIconUE: React.ReactElement =
      Product.brand?.uuid && Product.mpn ? (
        <CheckCircleOutlined style={{ color: green.primary }} />
      ) : (
        <CloseCircleOutlined style={{ color: red.primary }} />
      );

    setStatusIcon(statusIconUE);
  }, [Product.brand?.uuid, Product.mpn]);

  return (
    <Collapse
      onChange={(keys: string[]): void => {
        if (keys.includes('brand-and-indentification')) {
          dispatch(brandsLoadAll());
        }
      }}
    >
      <Panel
        header={
          <Space size={8} align="start" direction="horizontal">
            <strong>Brand and identification</strong>
            {statusIcon}
          </Space>
        }
        key="brand-and-indentification"
      >
        <Form
          form={form}
          onFinish={(values: IForm): void => onFinish(values)}
          layout="vertical"
          initialValues={{
            brand: Product.brand,
            mpn: Product.mpn,
            gtin: Product.gtin,
          }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name={['brand', 'uuid']}
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Brand"
              >
                <Select
                  showSearch
                  placeholder="Select brand"
                  disabled={Product.inProcess}
                  filterOption={getFilterOption}
                  filterSort={getFilterSort}
                >
                  {Brand.brands?.map(
                    (brand: IBrand): React.ReactElement => (
                      <Option value={brand.uuid} key={brand.uuid}>
                        {brand.name}
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="mpn"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'string',
                    min: 2,
                  },
                ]}
                label="MPN"
              >
                <Input
                  placeholder="MPN"
                  disabled={Product.inProcess}
                  addonAfter={
                    <CopyToClipboard
                      text={Product.mpn || ''}
                      size="small"
                      type="link"
                    />
                  }
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="gtin"
                rules={[
                  {
                    required: false,
                  },
                  {
                    type: 'string',
                    min: 2,
                  },
                ]}
                label="GTIN / EAN"
              >
                <Input
                  placeholder="GTIN / EAN"
                  disabled={Product.inProcess}
                  addonAfter={
                    <CopyToClipboard
                      text={Product.gtin || ''}
                      size="small"
                      type="link"
                    />
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={Product.inProcess}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Brand, Product }: RootState) => ({
  Product,
  Brand,
});

export default connect(mapStateToProps)(BrandAndIdentification);
