import { Menu } from 'antd';
import { type MenuItemType } from 'antd/es/menu/interface';
import React from 'react';

import Link from './link';

function SidebarSettings(): React.ReactElement {
  const menuItems: MenuItemType[] = [
    {
      key: 'countries',
      label: <Link to="/settings/countries" label="Countries" />,
    },
    {
      key: 'tags',
      label: <Link to="/settings/tags" label="Tags" />,
    },
  ];

  return <Menu items={menuItems} />;
}

export default SidebarSettings;
