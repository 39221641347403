import { createReducer } from '@reduxjs/toolkit';

import {
  loadProductProperties,
  loadProductPropertiesResponse,
  loadPropertiesByRawNames,
  loadPropertiesByRawNamesResponse,
  propertiesLoad,
  propertiesLoadAllTypes,
  propertiesLoadAllTypesResponse,
  propertiesLoadResponse,
  propertyAdd,
  propertyAddResponse,
} from './actions';
import { type IPropertiesState } from './types';

export const preloadedState: IPropertiesState = {
  inProcess: false,
  codes: [],
  types: [],
  product: {
    properties: undefined,
  },
  property: {
    code: undefined,
  },
  properties: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(propertiesLoadAllTypes, (state) => {
    state.inProcess = true;
  });

  builder.addCase(propertiesLoadAllTypesResponse, (state, action) => {
    state.types = action.payload.types;
    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(propertiesLoad, (state) => {
    state.inProcess = true;
  });

  builder.addCase(propertiesLoadResponse, (state, action) => {
    state.codes = action.payload.property.codes.sort(
      (a: string, b: string): number => a.localeCompare(b),
    );
    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(loadProductProperties, (state) => {
    state.inProcess = true;
  });

  builder.addCase(loadProductPropertiesResponse, (state, action) => {
    state.product.properties = action.payload.properties;
    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(propertyAdd, (state) => {
    state.inProcess = true;
    state.property = { code: undefined };
  });

  builder.addCase(propertyAddResponse, (state, action) => {
    state.codes.push(action.payload.property.code);
    state.codes.sort((a: string, b: string): number => a.localeCompare(b));
    state.inProcess = false;
    state.property = action.payload.property;
    state.updatedAt = Date.now();
  });

  builder.addCase(loadPropertiesByRawNames, (state) => {
    state.inProcess = true;
    state.properties = undefined;
  });

  builder.addCase(loadPropertiesByRawNamesResponse, (state, action) => {
    state.properties = action.payload.properties;
    state.inProcess = false;
    state.updatedAt = Date.now();
  });
});
