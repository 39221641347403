import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects';
import { Manager, Socket } from 'socket.io-client';

import { BRAND_ACTION } from './reducers/brand/types';
import { CATEGORIES_ACTION } from './reducers/categories/types';
import { CATEGORY_ACTION } from './reducers/category/types';
import { COMPARISON_PORTAL_ACTION } from './reducers/comparison-portal/types';
import { COUNTRIES_ACTION } from './reducers/countries/types';
import { COUNTRY_ACTION } from './reducers/country/types';
import { DASHBOARD_ACTION } from './reducers/dashboard/types';
import { DISCOUNT_ACTION } from './reducers/discount/types';
import { DOWNLOADER_ACTION } from './reducers/downloader/types';
import { EBAY_ACTION } from './reducers/ebay/types';
import { FEATURE_FLAGS_ACTION } from './reducers/feature-flags/types';
import { MESSENGER_ACTION } from './reducers/messenger/types';
import { ORDER_ACTION } from './reducers/order/types';
import { ORDERS_ACTION } from './reducers/orders/types';
import { PRODUCT_ACTION } from './reducers/product/types';
import { PRODUCTS_ACTION } from './reducers/products/types';
import { PROPERTIES_ACTION } from './reducers/properties/types';
import { PROPERTY_ACTION } from './reducers/property/types';
import { REPORT_ACTION } from './reducers/report/types';
import { SEARCH_ACTION } from './reducers/search/types';
import { send } from './reducers/send-request';
import { TAGS_ACTION } from './reducers/tags/types';
import User from './reducers/user/saga';
import { USER_ACTION } from './reducers/user/types';

const manager = new Manager(window.location.origin);
const socket = manager.socket('/');

export const getSocket = (): Socket => socket;

const watch = function* (): Generator<ForkEffect<never>, void, string> {
  yield takeEvery(BRAND_ACTION.ADD, send, socket);
  yield takeEvery(BRAND_ACTION.LOAD_ALL, send, socket);

  yield takeEvery(CATEGORIES_ACTION.ADD, send, socket);
  yield takeEvery(CATEGORIES_ACTION.LOAD_ALL, send, socket);
  yield takeEvery(
    CATEGORIES_ACTION.GET_LIKELY_PRODUCT_CATEGORIES,
    send,
    socket,
  );

  yield takeEvery(CATEGORY_ACTION.LOAD, send, socket);
  yield takeEvery(CATEGORY_ACTION.SAVE_POSITION, send, socket);
  yield takeEvery(CATEGORY_ACTION.SAVE_PROPERTY_LIST_CODES, send, socket);
  yield takeEvery(CATEGORY_ACTION.SAVE_SUB_CATEGORIES, send, socket);
  yield takeEvery(CATEGORY_ACTION.UPDATE_NAME, send, socket);

  yield takeEvery(DASHBOARD_ACTION.LOAD_SESSIONS, send, socket);

  yield takeEvery(DISCOUNT_ACTION.DELETE, send, socket);
  yield takeEvery(DISCOUNT_ACTION.LOAD_ALL, send, socket);
  yield takeEvery(DISCOUNT_ACTION.LOAD_TYPES, send, socket);
  yield takeEvery(DISCOUNT_ACTION.SAVE, send, socket);

  yield takeEvery(DOWNLOADER_ACTION.ADD_WAREHOUSE_PRODUCT, send, socket);
  yield takeEvery(
    DOWNLOADER_ACTION.DOWNLOAD_ALL_PHOTOS_WAREHOUSE_PRODUCT,
    send,
    socket,
  );
  yield takeEvery(DOWNLOADER_ACTION.DELETE_WAREHOUSE_PRODUCT, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.HIDE_WAREHOUSE_PRODUCT, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.LOAD_PRODUCT, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.LOAD_PRODUCTS, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORIES, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORY, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCT, send, socket);
  yield takeEvery(
    DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS_BY_WAREHOUSE_CATEGORY,
    send,
    socket,
  );
  yield takeEvery(DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTIES, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTY, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.PAIR_WAREHOUSE_CATEGORY, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.SYNC_WAREHOUSE_PRODUCT, send, socket);
  yield takeEvery(DOWNLOADER_ACTION.UNASSIGN_WAREHOUSE_PRODUCT, send, socket);
  yield takeEvery(
    DOWNLOADER_ACTION.WAREHOUSE_PRODUCT_PAIR_WITH_EXISTING_PRODUCT,
    send,
    socket,
  );

  yield takeEvery(EBAY_ACTION.CATEGORY_LOAD_ASPECTS, send, socket);
  yield takeEvery(EBAY_ACTION.CATEGORY_LOAD, send, socket);
  yield takeEvery(EBAY_ACTION.CATEGORY_SAVE_ASPECTS, send, socket);
  yield takeEvery(EBAY_ACTION.CATEGORY_SAVE, send, socket);
  yield takeEvery(EBAY_ACTION.CATEGORY_SUGGESTIONS, send, socket);
  yield takeEvery(EBAY_ACTION.INVENTORY_ITEM_DELETE, send, socket);
  yield takeEvery(EBAY_ACTION.INVENTORY_ITEM_LOAD, send, socket);
  yield takeEvery(EBAY_ACTION.INVENTORY_ITEM_PUT, send, socket);
  yield takeEvery(
    EBAY_ACTION.MARKETPLACE_LOAD_FULFILLMENT_POLICIES,
    send,
    socket,
  );
  yield takeEvery(EBAY_ACTION.MARKETPLACE_LOAD_PAYMENT_POLICIES, send, socket);
  yield takeEvery(EBAY_ACTION.MARKETPLACE_LOAD_RETURN_POLICIES, send, socket);
  yield takeEvery(EBAY_ACTION.MARKETPLACE_LOAD, send, socket);
  yield takeEvery(EBAY_ACTION.MARKETPLACE_RELOAD_CATEGORIES, send, socket);
  yield takeEvery(
    EBAY_ACTION.MARKETPLACE_RELOAD_DEFAULT_CATEGORY_ID,
    send,
    socket,
  );
  yield takeEvery(
    EBAY_ACTION.MARKETPLACE_RELOAD_FULFILLMENT_POLICIES,
    send,
    socket,
  );
  yield takeEvery(
    EBAY_ACTION.MARKETPLACE_RELOAD_PAYMENT_POLICIES,
    send,
    socket,
  );
  yield takeEvery(EBAY_ACTION.MARKETPLACE_RELOAD_RETURN_POLICIES, send, socket);
  yield takeEvery(EBAY_ACTION.MARKETPLACE_SET_CURRENCY, send, socket);
  yield takeEvery(EBAY_ACTION.MARKETPLACE_SET_IS_ACTIVE, send, socket);
  yield takeEvery(EBAY_ACTION.MARKETPLACE_SET_LANGUAGE, send, socket);
  yield takeEvery(EBAY_ACTION.MARKETPLACES_LOAD, send, socket);
  yield takeEvery(EBAY_ACTION.OFFER_DELETE, send, socket);
  yield takeEvery(EBAY_ACTION.OFFER_POST, send, socket);
  yield takeEvery(EBAY_ACTION.OFFER_PUBLISH, send, socket);
  yield takeEvery(EBAY_ACTION.OFFER_PUT, send, socket);
  yield takeEvery(EBAY_ACTION.OFFERS_LOAD, send, socket);
  yield takeEvery(EBAY_ACTION.PRODUCT_LOAD, send, socket);
  yield takeEvery(EBAY_ACTION.PRODUCT_SAVE, send, socket);
  yield takeEvery(EBAY_ACTION.USER_CODE, send, socket);
  yield takeEvery(EBAY_ACTION.USER_URL, send, socket);

  yield takeEvery(FEATURE_FLAGS_ACTION.LOAD_ALL, send, socket);

  yield takeEvery(MESSENGER_ACTION.ADMIN_CONNECTED, send, socket);
  yield takeEvery(MESSENGER_ACTION.ADMIN_DISCONNECTED, send, socket);
  yield takeEvery(MESSENGER_ACTION.LOAD_LEADS, send, socket);
  yield takeEvery(MESSENGER_ACTION.SEND_MESSAGE, send, socket);
  yield takeEvery(MESSENGER_ACTION.SET_ACTIVE_LEAD, send, socket);

  yield takeEvery(ORDER_ACTION.LOAD, send, socket);
  yield takeEvery(ORDER_ACTION.SAVE_ORDER_TAGS, send, socket);
  yield takeEvery(ORDER_ACTION.SENT_REVIEW_TO_CUSTOMER, send, socket);
  yield takeEvery(ORDER_ACTION.SET_ORDER_STATUS_CODE, send, socket);
  yield takeEvery(ORDER_ACTION.SET_REVIEW_STATUS_CODE, send, socket);
  yield takeEvery(ORDER_ACTION.SENT_SHIPPING_TIME_TO_CUSTOMER, send, socket);

  yield takeEvery(ORDERS_ACTION.LOAD, send, socket);

  yield takeEvery(COMPARISON_PORTAL_ACTION.LOAD_CATEGORIES_ALL, send, socket);
  yield takeEvery(
    COMPARISON_PORTAL_ACTION.LOAD_COMPARISON_PORTALS_ALL,
    send,
    socket,
  );
  yield takeEvery(
    COMPARISON_PORTAL_ACTION.ASSIGN_COMPARISON_PORTAL_CATEGORY,
    send,
    socket,
  );

  yield takeEvery(COUNTRIES_ACTION.LOAD_ALL, send, socket);

  yield takeEvery(COUNTRY_ACTION.LOAD, send, socket);
  yield takeEvery(COUNTRY_ACTION.SAVE_DELIVERY_DESCRIPTION, send, socket);
  yield takeEvery(COUNTRY_ACTION.SAVE_DELIVERY_TIME, send, socket);
  yield takeEvery(COUNTRY_ACTION.SAVE_DESCRIPTION, send, socket);

  yield takeEvery(PRODUCT_ACTION.ASSIGN_RELATED_PRODUCTS, send, socket);
  yield takeEvery(PRODUCT_ACTION.BRAND_SET, send, socket);
  yield takeEvery(PRODUCT_ACTION.COPY_DESCRIPTIONS, send, socket);
  yield takeEvery(PRODUCT_ACTION.CURRENCIES_PRICES_UPDATE, send, socket);
  yield takeEvery(PRODUCT_ACTION.DIMENSIONS_UPDATE, send, socket);
  yield takeEvery(PRODUCT_ACTION.FEATURE_FLAG_DESTROY, send, socket);
  yield takeEvery(PRODUCT_ACTION.LOAD, send, socket);
  yield takeEvery(PRODUCT_ACTION.NAMES_DESCRIPTIONS_SLUGS_UPDATE, send, socket);
  yield takeEvery(PRODUCT_ACTION.PHOTO_DELETE, send, socket);
  yield takeEvery(PRODUCT_ACTION.PHOTO_DOWNLOAD_FROM_URLS, send, socket);
  yield takeEvery(PRODUCT_ACTION.PHOTO_POSITION_SAVE, send, socket);
  yield takeEvery(PRODUCT_ACTION.PRODUCTS_FIND_IN_ELASTIC_SEARCH, send, socket);
  yield takeEvery(PRODUCT_ACTION.PROPERTIES_SAVE, send, socket);
  yield takeEvery(PRODUCT_ACTION.SAVE_CATEGORIES, send, socket);
  yield takeEvery(PRODUCT_ACTION.SET_ACTIVE, send, socket);
  yield takeEvery(PRODUCT_ACTION.STOCK_UPDATE, send, socket);

  yield takeEvery(PRODUCTS_ACTION.ADD, send, socket);
  yield takeEvery(PRODUCTS_ACTION.GET_BY_GTIN, send, socket);
  yield takeEvery(PRODUCTS_ACTION.GET_BY_MPN, send, socket);
  yield takeEvery(PRODUCTS_ACTION.LOAD_ALL_SHORT, send, socket);
  yield takeEvery(PRODUCTS_ACTION.LOAD_ALL, send, socket);
  yield takeEvery(PRODUCTS_ACTION.LOAD_BY_CATEGORY, send, socket);

  yield takeEvery(PROPERTIES_ACTION.ADD, send, socket);
  yield takeEvery(PROPERTIES_ACTION.LOAD_BY_RAW_NAMES, send, socket);
  yield takeEvery(PROPERTIES_ACTION.LOAD_PRODUCT_PROPERTIES, send, socket);
  yield takeEvery(PROPERTIES_ACTION.LOAD, send, socket);

  yield takeEvery(PROPERTY_ACTION.LOAD, send, socket);
  yield takeEvery(PROPERTY_ACTION.SAVE_CATEGORIES_UUIDS, send, socket);
  yield takeEvery(PROPERTY_ACTION.SAVE_WAREHOUSE_PROPERTIES, send, socket);
  yield takeEvery(PROPERTY_ACTION.UPDATE_NAME, send, socket);
  yield takeEvery(PROPERTY_ACTION.UPDATE_NAMES, send, socket);
  yield takeEvery(PROPERTY_ACTION.UPDATE_TYPE, send, socket);
  yield takeEvery(PROPERTY_ACTION.UPDATE_UNIT, send, socket);
  yield takeEvery(PROPERTY_ACTION.UPDATE_VALUE_NAME, send, socket);
  yield takeEvery(PROPERTY_ACTION.UPDATE_VALUE_NAMES, send, socket);

  yield takeEvery(REPORT_ACTION.LOAD_PRODUCT_LANGUAGES, send, socket);
  yield takeEvery(REPORT_ACTION.LOAD_PROPERTY_LANGUAGES, send, socket);
  yield takeEvery(REPORT_ACTION.LOAD_PROPERTY_VALUE_LANGUAGES, send, socket);
  yield takeEvery(REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TO_IMPORT, send, socket);
  yield takeEvery(REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TREND, send, socket);
  yield takeEvery(REPORT_ACTION.LOAD_WAREHOUSE_PRODUCTS_PAIRING, send, socket);

  yield takeEvery(SEARCH_ACTION.SEARCH_QUERY, send, socket);

  yield takeEvery(TAGS_ACTION.ADD, send, socket);
  yield takeEvery(TAGS_ACTION.LOAD_ALL, send, socket);

  yield takeEvery(USER_ACTION.LOGIN, User.login, socket);
  yield takeEvery(USER_ACTION.LOGOUT, User.logout, socket);
};

export const mySaga = function* (): Generator<
  AllEffect<Generator<ForkEffect<never>, void, string>>,
  void,
  void
> {
  yield all([watch()]);
};
