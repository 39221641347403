import { Menu } from 'antd';
import { type MenuItemType } from 'antd/es/menu/interface';
import React from 'react';

import Link from './link';

function SidebarOffer(): React.ReactElement {
  const menuItems: MenuItemType[] = [
    {
      key: 'marketplaces',
      label: <Link to="/ebay/marketplaces" label="Marketplaces" />,
    },
  ];

  return <Menu items={menuItems} />;
}

export default SidebarOffer;
