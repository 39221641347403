import { type IPropertyValue } from '@mahawi/eshop-common';
import { Divider, Pagination, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type RootState } from '../../reducers';
import { type IPropertyState } from '../../reducers/property/types';
import BubbleComponent from '../bubble';
import ValueName from './value-name';

function PropertyType({
  Property,
}: {
  Property: IPropertyState;
}): React.ReactElement {
  const [propertyValueEl, setPropertyValueEl] = useState<React.ReactElement[]>(
    [],
  );
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect((): void => {
    if (!Property.property?.values) {
      return;
    }

    const start: number = (page - 1) * pageSize;
    const end: number = start + pageSize;

    const propertyValuesToShown: IPropertyValue[] =
      Property.property?.values.slice(start, end);

    const propertyValueElUE: React.ReactElement[] = propertyValuesToShown.map(
      (propertyValue: IPropertyValue): React.ReactElement => {
        return (
          <ValueName key={propertyValue.code} propertyValue={propertyValue} />
        );
      },
    );

    setPropertyValueEl(propertyValueElUE);
  }, [Property.property?.values, page, pageSize]);

  return (
    <BubbleComponent span={16}>
      <Space size={16} align="start" direction="horizontal">
        <strong>Value</strong>

        <Pagination
          current={page}
          total={Property.property?.values?.length}
          showLessItems
          showSizeChanger
          showPrevNextJumpers
          showQuickJumper
          showTitle
          showTotal={(total: number, range: [number, number]): string =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(
            paginatorPage: number,
            paginatorPageSize: number,
          ): void => {
            setPage(paginatorPage);
            setPageSize(paginatorPageSize);
          }}
        />
      </Space>

      <Divider />

      {propertyValueEl}
    </BubbleComponent>
  );
}

const mapStateToProps = ({
  Property,
}: RootState): {
  Property: IPropertyState;
} => ({
  Property,
});

export default connect(mapStateToProps)(PropertyType);
