import { GlobalOutlined, ReloadOutlined } from '@ant-design/icons';
import { type IProductFeatureFlag } from '@mahawi/eshop-common';
import {
  getLowerCaseLanguageCode,
  getTranslation,
} from '@mahawi/eshop-common/dist/src/translation';
import {
  Button,
  Divider,
  Popconfirm,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { type Dispatch } from 'redux';

import CopyToClipboard from '../components/copy-to-clipboard';
import LanguageButtons from '../components/language-buttons';
import BrandAndIdentification from '../components/product/brand-and-identification';
import Categories from '../components/product/categories';
import Dimensions from '../components/product/dimensions';
import EbayProduct from '../components/product/ebay-product';
import Photo from '../components/product/photo';
import Prices from '../components/product/prices';
import ProductLanguage from '../components/product/product-language';
import Properties from '../components/product/properties';
import Related from '../components/product/related';
import Stock from '../components/product/stock';
import { featureFlagTags } from '../fragments/feature-flag-tags';
import { type RootState } from '../reducers';
import { type IConfigState } from '../reducers/config/types';
import { type IFeatureFlagsState } from '../reducers/feature-flags/types';
import { type ILanguageState } from '../reducers/language/types';
import {
  load as productLoad,
  productFeatureFlagDestroy,
  productSetActive,
} from '../reducers/product/actions';
import { type IProductState } from '../reducers/product/types';

function ProductContainer({
  Product,
  Config,
  dispatch,
  Language,
  FeatureFlags,
}: {
  Product: IProductState;
  Config: IConfigState;
  dispatch: Dispatch;
  Language: ILanguageState;
  FeatureFlags: IFeatureFlagsState;
}): React.ReactElement {
  const { productUUID } = useParams();

  const [productNameLocalized, setProductNameLocalized] = useState<string>('');
  const [visibilityElement, setVisibilityElement] = useState<
    React.ReactElement | undefined
  >(undefined);
  const [featureFlagEls, setFeatureFlagEls] = useState<React.ReactElement[]>(
    [],
  );
  const [languageTypeCodeError, setLanguageTypeCodeError] = useState<string[]>(
    [],
  );

  const tagOnClose = useCallback(
    (productUUID: string, productFeatureFlag: IProductFeatureFlag): void => {
      dispatch(productFeatureFlagDestroy(productUUID, productFeatureFlag.code));
    },
    [dispatch],
  );

  useEffect((): void => {
    if (productUUID) {
      dispatch(productLoad(productUUID));
    }
  }, [dispatch, productUUID]);

  useEffect((): void => {
    const productNameLocalizedUE: string | null = getTranslation(
      Product.names,
      Language.languageType,
    );

    setProductNameLocalized(productNameLocalizedUE || '---');
  }, [Product.names, Language.languageType, Product.updatedAt]);

  useEffect((): void => {
    const buttonElement: React.ReactElement = Product.isActive ? (
      <Button size="large" type="primary">
        Product is active
      </Button>
    ) : (
      <Button size="large" danger>
        Product is disabled
      </Button>
    );

    const title: string = Product.isActive
      ? 'Disable product'
      : 'Enable product';
    const description: string = Product.isActive
      ? 'Product will be disabled for export to CSS and PCP.'
      : 'Product will be enabled for export to CSS and PCP.';

    const visibilityElementUE: React.ReactElement = (
      <Popconfirm
        title={title}
        description={description}
        onConfirm={(): void => {
          dispatch(productSetActive(Product.uuid, !Product.isActive));
        }}
        okText="Yes"
        cancelText="No"
        placement="topLeft"
      >
        {buttonElement}
      </Popconfirm>
    );

    setVisibilityElement(visibilityElementUE);
  }, [Product, dispatch, Product.updatedAt]);

  useEffect((): void => {
    const featureFlagElsUE: React.ReactElement[] = featureFlagTags({
      productUUID: Product.uuid,
      productFeatureFlags: Product.featureFlags,
      FeatureFlags,
      onClose: tagOnClose,
    });

    setFeatureFlagEls(featureFlagElsUE);
  }, [
    FeatureFlags,
    FeatureFlags.featureFlags,
    FeatureFlags.updatedAt,
    Product.featureFlags,
    Product.updatedAt,
    Product.uuid,
  ]);

  if (Product.names.length === 0 || !productUUID) {
    return <Skeleton active />;
  }

  return (
    <>
      <Typography.Title level={2}>{productNameLocalized}</Typography.Title>

      <Space size={16} align="center">
        <Tooltip title="Reload product">
          <Button
            size="large"
            color="primary"
            onClick={(): void => {
              dispatch(productLoad(productUUID));
            }}
            disabled={Product.inProcess}
          >
            <ReloadOutlined />
          </Button>
        </Tooltip>

        <a
          href={`${Config.url}/${getLowerCaseLanguageCode(Language.languageType)}/product/${
            Product.uuid
          }`}
          target="_blank"
          rel="noreferrer noopener nofollow"
          aria-label="Open product in new tab"
        >
          <Tooltip title="Open product in Eshop">
            <Button size="large" color="primary">
              <GlobalOutlined />
            </Button>
          </Tooltip>
        </a>

        <CopyToClipboard
          text={productUUID}
          title="Copy product UUID"
          size="large"
        />

        {visibilityElement}

        {featureFlagEls}
      </Space>

      <Divider />

      <LanguageButtons languageTypeCodeError={languageTypeCodeError} />

      <Divider />

      <ProductLanguage setLanguageTypeCodeError={setLanguageTypeCodeError} />

      <Divider />

      <BrandAndIdentification />

      <Divider />

      <Categories />

      <Divider />

      <Photo />

      <Divider />

      <Dimensions />

      <Divider />

      <Stock />

      <Divider />

      <Prices />

      <Divider />

      <Properties />

      <Divider />

      <Related />

      <Divider />

      <EbayProduct />
    </>
  );
}

const mapStateToProps = ({
  Config,
  Currency,
  Language,
  Product,
  FeatureFlags,
}: RootState) => ({
  Config,
  Currency,
  Language,
  Product,
  FeatureFlags,
});

export default connect(mapStateToProps)(ProductContainer);
