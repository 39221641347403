import { EOrderStatus } from '@mahawi/eshop-common/dist/src/types';
import React from 'react';

import OrderLayout from '../../components/orders/order-layout';

function OrdersAllContainer(): React.ReactElement {
  return <OrderLayout orderStatusTypeCodes={[EOrderStatus.CANCELLED]} />;
}

export default OrdersAllContainer;
