import { toNumber } from '@mahawi/eshop-common/dist/src/to-number';
import {
  Alert,
  Button,
  Descriptions,
  Input,
  Popconfirm,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import React, { type ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { type Dispatch } from 'redux';

import { type RootState } from '../reducers';
import {
  countryLoad,
  countrySaveDeliveryDescription,
  countrySaveDeliveryTime,
  countrySaveDescription,
} from '../reducers/country/actions';
import { type ICountryState } from '../reducers/country/types';

function SettingsCountryContainer({
  dispatch,
  Country,
}: {
  dispatch: Dispatch;
  Country: ICountryState;
}): React.ReactElement {
  const { isoCode } = useParams();

  const [description, setDescription] = useState<string>('');
  const [deliveryDescription, setDeliveryDescription] = useState<string>('');
  const [deliveryTime, setDeliveryTime] = useState<string>('');

  useEffect((): void => {
    if (isoCode) {
      dispatch(countryLoad(isoCode));
    }
  }, [isoCode, dispatch]);

  if (Country.country === null) {
    return (
      <Alert
        message="Error"
        description="Country not found"
        type="error"
        showIcon
      />
    );
  }

  if (Country.country === undefined) {
    return <Spin size="large" spinning />;
  }

  return (
    <Descriptions
      title={`Country ${isoCode}`}
      bordered
      column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }}
    >
      <Descriptions.Item label="Name">
        {Country.country?.name}
      </Descriptions.Item>

      <Descriptions.Item label="ISOCode">
        {Country.country?.isoCode}
      </Descriptions.Item>

      <Descriptions.Item label="ISOCode2">
        {Country.country?.isoCode2}
      </Descriptions.Item>

      <Descriptions.Item label="Description" span={3}>
        <Tooltip
          title="Descriptions is shown on the delivery page."
          placement="top"
        >
          <Space.Compact style={{ width: '100%' }}>
            <Input
              defaultValue={Country.country?.description || ''}
              placeholder="Description of the country."
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                setDescription(e.target.value)
              }
            />
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={(): void => {
                if (!Country.country) {
                  return;
                }

                dispatch(
                  countrySaveDescription(Country.country?.isoCode, description),
                );
              }}
            >
              <Button type="primary">Save</Button>
            </Popconfirm>
          </Space.Compact>
        </Tooltip>
      </Descriptions.Item>

      <Descriptions.Item label="Delivery description" span={3}>
        <Tooltip
          title="Delivery descriptions is shown on the delivery page."
          placement="top"
        >
          <Space.Compact style={{ width: '100%' }}>
            <Input
              defaultValue={Country.country?.delivery.description || ''}
              placeholder="Parcel services, restrictions, etc."
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                setDeliveryDescription(e.target.value)
              }
            />
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={(): void => {
                if (!Country.country) {
                  return;
                }

                dispatch(
                  countrySaveDeliveryDescription(
                    Country.country?.isoCode,
                    deliveryDescription,
                  ),
                );
              }}
            >
              <Button type="primary">Save</Button>
            </Popconfirm>
          </Space.Compact>
        </Tooltip>
      </Descriptions.Item>

      <Descriptions.Item label="Delivery time" span={3}>
        <Tooltip
          title="Delivery time is shown on the delivery page."
          placement="top"
        >
          <Space.Compact style={{ width: '100%' }}>
            <Input
              defaultValue={Country.country?.delivery.time || ''}
              placeholder="Delivery time approx. in days."
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                setDeliveryTime(e.target.value)
              }
              type="number"
              addonAfter="business days"
            />
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={(): void => {
                if (!Country.country) {
                  return;
                }

                dispatch(
                  countrySaveDeliveryTime(
                    Country.country?.isoCode,
                    toNumber(deliveryTime),
                  ),
                );
              }}
            >
              <Button type="primary">Save</Button>
            </Popconfirm>
          </Space.Compact>
        </Tooltip>
      </Descriptions.Item>
    </Descriptions>
  );
}

const mapStateToProps = ({ Country }: RootState) => ({ Country });

export default connect(mapStateToProps)(SettingsCountryContainer);
