import { GlobalOutlined } from '@ant-design/icons';
import { type IPayment } from '@mahawi/eshop-common';
import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import { getFlag } from '@mahawi/eshop-common/dist/src/get-flag';
import { round } from '@mahawi/eshop-common/dist/src/round';
import { getLowerCaseLanguageCode } from '@mahawi/eshop-common/dist/src/translation';
import { EReviewStatusType } from '@mahawi/eshop-common/dist/src/types';
import { type ITag } from '@mahawi/eshop-common/dist/src/types';
import { EOrderStatus } from '@mahawi/eshop-common/dist/src/types/order-types';
import {
  Alert,
  Button,
  DatePicker,
  Descriptions,
  Drawer,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { type Dispatch } from 'redux';

import CopyToClipboard from '../components/copy-to-clipboard';
import GoogleMapsButton from '../components/google-maps-button';
import ProductTable from '../components/product/table';
import ReviewPreview from '../components/review/review-preview';
import { tagRender } from '../components/tags/tag-render';
import { type RootState } from '../reducers';
import { type IConfigState } from '../reducers/config/types';
import { type ILanguageState } from '../reducers/language/types';
import {
  orderLoad,
  orderSetOrderStatusCode,
  reviewSentToCustomer,
  reviewSetStatusCode,
  saveOrderTags,
  sentShippingTimeToCustomer,
} from '../reducers/order/actions';
import { type IOrderState } from '../reducers/order/types';
import { type IOrdersState } from '../reducers/orders/types';
import { type ITagsState } from '../reducers/tags/types';

const { Text } = Typography;
const { Option } = Select;

function OrderContainer({
  dispatch,
  Order,
  Orders,
  Config,
  Language,
  Tags,
}: {
  dispatch: Dispatch;
  Order: IOrderState;
  Orders: IOrdersState;
  Config: IConfigState;
  Language: ILanguageState;
  Tags: ITagsState;
}): React.ReactElement {
  const { orderUUID } = useParams();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState<string>();
  const [drawerContent, setDrawerContent] = useState<React.ReactElement>();
  const [newOrderStatusCode, setNewOrderStatusCode] = useState<
    EOrderStatus | undefined
  >();
  const [newReviewStatusCode, setNewReviewStatusCode] = useState<
    EReviewStatusType | undefined
  >();
  const [tagOptions, setTagOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [orderTags, setOrderTags] = useState<string[]>([]);
  const [shippingDate, setShippingDate] = useState<dayjs.Dayjs>(dayjs());

  useEffect((): void => {
    if (orderUUID) {
      dispatch(orderLoad(orderUUID));
    }
  }, [dispatch, orderUUID]);

  useEffect((): void => {
    if (Tags.tags) {
      setTagOptions(
        Tags.tags.map((tag: ITag) => ({ label: tag.name, value: tag.color })),
      );
    }
  }, [Tags.tags]);

  const { order } = Order;

  if (order === undefined) {
    return <Skeleton active />;
  }

  if (order === null) {
    return (
      <Alert
        message="Order not found"
        description={`Order with uuid ${orderUUID} not found.`}
        type="error"
        showIcon
      />
    );
  }

  const { billingAddress, deliveryAddress, discount } = order;

  const uuidSegments: string[] = order.uuid.split('-');
  const firstSegment: string = uuidSegments[0];

  return (
    <>
      <Descriptions bordered column={4}>
        <Descriptions.Item label="UUID" span={1}>
          <Space size={16} align="baseline">
            <Text strong>{order.uuid}</Text>
            <CopyToClipboard text={order.uuid} type="link" />
            <a
              href={`${Config.url}/${getLowerCaseLanguageCode(Language.languageType)}/order/${
                order.uuid
              }`}
              target="_blank"
              rel="noreferrer noopener nofollow"
              aria-label="Open order in new tab"
            >
              <GlobalOutlined />
            </a>
          </Space>
          <br />

          <Space size={16} align="baseline">
            <Text strong>{firstSegment}</Text>

            <CopyToClipboard text={firstSegment} type="link" />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Price" span={2}>
          <Space direction="vertical">
            <Space size={16} align="baseline">
              <Text>total price:</Text>
              <Text strong>
                {formatCurrency(order.price, order.currencyType)}
              </Text>
              <CopyToClipboard text={String(order.price)} type="link" />
            </Space>

            <Space size={16} align="baseline">
              <Text>items price:</Text>
              <Text strong>
                {formatCurrency(
                  round(
                    order.price - order.deliveryFee,
                    order.currencyType.decimalPrecision,
                  ),
                  order.currencyType,
                )}
              </Text>
              <CopyToClipboard
                text={String(order.price - order.deliveryFee)}
                type="link"
              />
            </Space>

            <Space size={16} align="baseline">
              <Text>delivery fee:</Text>
              <Text strong>
                {formatCurrency(order.deliveryFee, order.currencyType)}
              </Text>
              <CopyToClipboard text={String(order.deliveryFee)} type="link" />
            </Space>

            <Space size={16} align="baseline">
              <Text>packages count:</Text>
              <Text strong>{order.packagesCount}</Text>
            </Space>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Discount" span={1}>
          <Space direction="vertical">
            <Space size={16} align="baseline">
              <Text>discount code type:</Text>
              <Text strong>{discount.discountType.code || '------'}</Text>
            </Space>

            <Space size={16} align="baseline">
              <Text>code:</Text>
              <Text strong>{discount.code || '------'}</Text>
            </Space>

            <Space size={16} align="baseline">
              <Text>value:</Text>
              <Text strong>{discount.value}</Text>
            </Space>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Status" span={2}>
          <Space size={16} direction="vertical">
            <Text strong>{order.orderStatusCode}</Text>

            <Space size={16}>
              <Select
                style={{ width: '15rem' }}
                onChange={setNewOrderStatusCode}
              >
                {Orders.statusTypeCodes?.map(
                  (orderStatusCode: string): React.ReactElement => (
                    <Option key={orderStatusCode} value={orderStatusCode}>
                      {orderStatusCode}
                    </Option>
                  ),
                )}
              </Select>

              <Popconfirm
                title="Are you sure to change order status?"
                description="Order status will be changed."
                onConfirm={(): void => {
                  if (newOrderStatusCode) {
                    dispatch(
                      orderSetOrderStatusCode(order.uuid, newOrderStatusCode),
                    );
                  }
                }}
                okText="Change"
                cancelText="Cancel"
                placement="topLeft"
              >
                <Button>Change order status</Button>
              </Popconfirm>
            </Space>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Dates" span={2}>
          <Space direction="vertical">
            <Space size={16} align="baseline">
              <Text>created at:</Text>
              <Text strong>
                {dayjs(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Text>
            </Space>

            <Space size={16} align="baseline">
              <Text>updated at:</Text>
              <Text strong>
                {dayjs(order.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
              </Text>
            </Space>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Tags" span={2}>
          <Space size={16}>
            <Select
              mode="multiple"
              tagRender={tagRender}
              defaultValue={Order.order?.tags || []}
              style={{ width: '15rem' }}
              options={tagOptions}
              onChange={(value: string[]): void => {
                setOrderTags(value);
              }}
            />

            <Button
              onClick={(): void => {
                dispatch(saveOrderTags(order.uuid, orderTags));
              }}
            >
              Save tags
            </Button>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Shipping date" span={2}>
          <Space size={16}>
            <DatePicker
              placeholder="Select shipping date"
              allowClear={false}
              minDate={dayjs()}
              defaultValue={shippingDate}
              onChange={(date: dayjs.Dayjs | null): void => {
                if (date) {
                  setShippingDate(date);
                }
              }}
            />

            <Popconfirm
              title="Are you sure to send email to customer with shipping date?"
              description="Email will be sent to customer."
              onConfirm={(): void => {
                dispatch(
                  sentShippingTimeToCustomer(
                    order.uuid,
                    shippingDate.format('YYYY-MM-DD'),
                  ),
                );
              }}
              okText="Send"
              cancelText="Cancel"
              placement="topLeft"
            >
              <Button
                disabled={
                  !shippingDate ||
                  shippingDate.isBefore(dayjs(), 'day') ||
                  (order && order.orderStatusCode !== EOrderStatus.PAID)
                }
              >
                Sent email to customer to with shipping date
              </Button>
            </Popconfirm>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Products" span={4}>
          <ProductTable
            products={order.products}
            currencyType={order.currencyType}
            withCopyLinks
          />
        </Descriptions.Item>

        <Descriptions.Item label="Billing address" span={2}>
          <Space direction="vertical">
            <Space size={16} align="baseline">
              <Text>name:</Text>
              <Text strong>{billingAddress.name}</Text>
              <CopyToClipboard text={billingAddress.name} type="link" />
              <GoogleMapsButton address={billingAddress} />
            </Space>

            <Space size={16} align="baseline">
              <Text>surname:</Text>
              <Text strong>{billingAddress.surname}</Text>
              <CopyToClipboard text={billingAddress.surname} type="link" />
            </Space>

            <Space size={16} align="baseline">
              <Text>street:</Text>
              <Text strong>{billingAddress.street}</Text>
              <CopyToClipboard text={billingAddress.street} type="link" />
            </Space>

            <Space size={16} align="baseline">
              <Text>city:</Text>
              <Text strong>{billingAddress.city}</Text>
              <CopyToClipboard text={billingAddress.city} type="link" />
            </Space>

            <Space size={16} align="baseline">
              <Text>zip:</Text>
              <Text strong>{billingAddress.zip}</Text>
              <CopyToClipboard text={billingAddress.zip} type="link" />
            </Space>

            <Space size={16} align="baseline">
              <Text>country ISO code:</Text>
              <Text strong>
                {billingAddress.countryTypeIsoCode}{' '}
                {getFlag({
                  countryTypeIsoCode: billingAddress.countryTypeIsoCode,
                })}
              </Text>
              <CopyToClipboard
                text={billingAddress.countryTypeIsoCode}
                type="link"
              />
            </Space>

            <Space size={16} align="baseline">
              <Text>phone:</Text>
              <Text strong>{billingAddress.phone}</Text>
              <CopyToClipboard text={billingAddress.phone} type="link" />
            </Space>

            <Space size={16} align="baseline">
              <Text>email:</Text>
              <Text strong>{billingAddress.email}</Text>
              <CopyToClipboard text={billingAddress.email} type="link" />
            </Space>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Delivery address" span={2}>
          <Space direction="vertical">
            <Space size={16} align="baseline">
              <Text>name:</Text>
              <Text strong>{deliveryAddress.name}</Text>
              {deliveryAddress.name && (
                <>
                  <CopyToClipboard text={deliveryAddress.name} type="link" />
                  <GoogleMapsButton address={deliveryAddress} />
                </>
              )}
            </Space>

            <Space size={16} align="baseline">
              <Text>surname:</Text>
              <Text strong>{deliveryAddress.surname}</Text>
              {deliveryAddress.surname && (
                <CopyToClipboard text={deliveryAddress.surname} type="link" />
              )}
            </Space>

            <Space size={16} align="baseline">
              <Text>street:</Text>
              <Text strong>{deliveryAddress.street}</Text>
              {deliveryAddress.street && (
                <CopyToClipboard text={deliveryAddress.street} type="link" />
              )}
            </Space>

            <Space size={16} align="baseline">
              <Text>city:</Text>
              <Text strong>{deliveryAddress.city}</Text>
              {deliveryAddress.city && (
                <CopyToClipboard text={deliveryAddress.city} type="link" />
              )}
            </Space>

            <Space size={16} align="baseline">
              <Text>zip:</Text>
              <Text strong>{deliveryAddress.zip}</Text>
              {deliveryAddress.zip && (
                <CopyToClipboard text={deliveryAddress.zip} type="link" />
              )}
            </Space>

            <Space size={16} align="baseline">
              <Text>country ISO code:</Text>
              <Text strong>
                {deliveryAddress.countryTypeIsoCode}{' '}
                {deliveryAddress.countryTypeIsoCode &&
                  getFlag({
                    countryTypeIsoCode: deliveryAddress.countryTypeIsoCode,
                  })}
              </Text>
              {deliveryAddress.countryTypeIsoCode && (
                <CopyToClipboard
                  text={deliveryAddress.countryTypeIsoCode}
                  type="link"
                />
              )}
            </Space>

            <Space size={16} align="baseline">
              <Text>phone:</Text>
              <Text strong>{deliveryAddress.phone}</Text>
              {deliveryAddress.phone && (
                <CopyToClipboard text={deliveryAddress.phone} type="link" />
              )}
            </Space>

            <Space size={16} align="baseline">
              <Text>email:</Text>
              <Text strong>{deliveryAddress.email}</Text>
              {deliveryAddress.email && (
                <CopyToClipboard text={deliveryAddress.email} type="link" />
              )}
            </Space>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Review" span={4}>
          <Space size={16} direction="vertical">
            {order.review ? (
              <>
                <Text strong>{order.review.reviewStatusType.code}</Text>

                <Space size={16} align="baseline">
                  <Select
                    style={{ width: '15rem' }}
                    onChange={setNewReviewStatusCode}
                  >
                    {Orders.reviewStatusTypeCodes?.map(
                      (reviewStatusCode: string): React.ReactElement => (
                        <Option key={reviewStatusCode} value={reviewStatusCode}>
                          {reviewStatusCode}
                        </Option>
                      ),
                    )}
                  </Select>

                  <Popconfirm
                    title="Are you sure to change review status?"
                    description="Review status will be changed."
                    onConfirm={(): void => {
                      if (newReviewStatusCode && order.review) {
                        dispatch(
                          reviewSetStatusCode(
                            order.review.uuid,
                            newReviewStatusCode,
                          ),
                        );
                      }
                    }}
                    okText="Change"
                    cancelText="Cancel"
                    placement="topLeft"
                  >
                    <Button>Change review status</Button>
                  </Popconfirm>
                </Space>

                <Space size={16} align="baseline">
                  <Text strong>{order.review.uuid}</Text>
                  <CopyToClipboard text={order.review.uuid} type="link" />
                  <a
                    href={`${Config.url}/${getLowerCaseLanguageCode(Language.languageType)}/review/${
                      order.review?.uuid
                    }`}
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                    aria-label="Open review in new tab"
                  >
                    <GlobalOutlined />
                  </a>
                </Space>

                {Order.order?.review?.reviewStatusType.code &&
                  [
                    EReviewStatusType.IN_PROGRESS,
                    EReviewStatusType.SUBMITTED,
                    EReviewStatusType.APPROVED,
                  ].includes(Order.order?.review?.reviewStatusType.code) && (
                    <Button
                      onClick={(): void => {
                        setDrawerTitle('Review');
                        setDrawerContent(<ReviewPreview />);
                        setOpenDrawer(!openDrawer);
                      }}
                    >
                      Show review
                    </Button>
                  )}

                {Order.order?.review?.reviewStatusType.code &&
                  [EReviewStatusType.CREATED].includes(
                    Order.order?.review?.reviewStatusType.code,
                  ) && (
                    <Popconfirm
                      title="Are you sure to send email to customer to review order?"
                      description="Email will be sent to customer."
                      onConfirm={(): void => {
                        if (Order.order?.uuid && Order.order?.review?.uuid) {
                          dispatch(reviewSentToCustomer(Order.order.uuid));
                        }
                      }}
                      okText="Send"
                      cancelText="Cancel"
                      placement="topLeft"
                    >
                      <Button>Sent email to customer to review order</Button>
                    </Popconfirm>
                  )}
              </>
            ) : (
              <Text strong>---</Text>
            )}
          </Space>
        </Descriptions.Item>

        {order.payments.map((payment: IPayment): React.ReactElement => {
          const label = `Payment ${payment.paymentVendorType} (${payment.paymentStatusType})`;

          return (
            <Descriptions.Item label={label} span={4} key={payment.uuid}>
              <Space direction="vertical">
                <Space size={16} align="baseline">
                  <Text>UUID:</Text>
                  <Text strong>{payment.uuid}</Text>
                  <CopyToClipboard text={payment.uuid} type="link" />
                </Space>

                <Space size={16} align="baseline">
                  <Text>ID:</Text>
                  <Text strong>{payment.thirdPartyPaymentId}</Text>
                  <CopyToClipboard
                    text={payment.thirdPartyPaymentId}
                    type="link"
                  />
                </Space>

                <Button
                  onClick={(): void => {
                    const content: React.ReactElement = (
                      <Typography.Text>
                        <small>
                          <pre>
                            {JSON.stringify(
                              payment.thirdPartyPaymentBody,
                              null,
                              2,
                            )}
                          </pre>
                        </small>
                      </Typography.Text>
                    );

                    setDrawerTitle('JSON response');
                    setDrawerContent(content);
                    setOpenDrawer(!openDrawer);
                  }}
                >
                  Show full JSON response
                </Button>
              </Space>
            </Descriptions.Item>
          );
        })}
      </Descriptions>

      <Drawer
        width="75%"
        title={drawerTitle}
        placement="right"
        onClose={(): void => setOpenDrawer(false)}
        open={openDrawer}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}

const mapStateToProps = ({
  Order,
  Orders,
  Config,
  Language,
  Tags,
}: RootState): {
  Order: IOrderState;
  Config: IConfigState;
  Language: ILanguageState;
  Orders: IOrdersState;
  Tags: ITagsState;
} => ({
  Order,
  Config,
  Language,
  Orders,
  Tags,
});

export default connect(mapStateToProps)(OrderContainer);
