import { Menu } from 'antd';
import { type MenuItemType } from 'antd/es/menu/interface';
import React from 'react';

import Link from './link';

function SidebarOffer(): React.ReactElement {
  const menuItems: MenuItemType[] = [
    {
      key: 'productLanguages',
      label: <Link to="/report/product-languages" label="Product languages" />,
    },
    {
      key: 'propertyLanguages',
      label: (
        <Link to="/report/property-languages" label="Property languages" />
      ),
    },
    {
      key: 'propertyValueLanguages',
      label: (
        <Link
          to="/report/property-value-languages"
          label="Property value languages"
        />
      ),
    },
    {
      key: 'warehouseProductToImport',
      label: (
        <Link
          to="/report/warehouse-product-to-import"
          label="Warehouse product to import"
        />
      ),
    },
    {
      key: 'warehouseProductTrend',
      label: (
        <Link
          to="/report/warehouse-product-trend"
          label="Warehouse product trend"
        />
      ),
    },
    {
      key: 'warehouseProductsPairing',
      label: (
        <Link
          to="/report/warehouse-products-pairing"
          label="Warehouse products pairing"
        />
      ),
    },
  ];

  return <Menu items={menuItems} />;
}

export default SidebarOffer;
