import { ReloadOutlined } from '@ant-design/icons';
import { type IWarehouseDetail } from '@mahawi/eshop-common';
import { type UnknownAction } from '@reduxjs/toolkit';
import { Button, Input, Select, Space } from 'antd';
import { type DefaultOptionType } from 'antd/es/select';
import React, { type ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../../reducers';
import { type IWarehouseState } from '../../reducers/warehouse/types';

type WarehouseProductFilterCallback = (
  warehouseUuid: string,
  minimalWeight: number,
  maximalWeight: number,
  minimalPrice: number,
  maximalPrice: number,
) => UnknownAction;

function WarehouseProductFilter({
  dispatch,
  cb,
  Warehouse,
}: {
  dispatch: Dispatch;
  cb: WarehouseProductFilterCallback;
  Warehouse: IWarehouseState;
}): React.ReactElement {
  const [warehouseOptions, setWarehouseOptions] = useState<DefaultOptionType[]>(
    [],
  );
  const [warehouseUuid, setWarehouseUuid] = useState<string | null>(null);

  const [minimalWeight, setMinimalWeight] = useState<number>(0.001);
  const [maximalWeight, setMaximalWeight] = useState<number>(30);
  const [minimalPrice, setMinimalPrice] = useState<number>(0);
  const [maximalPrice, setMaximalPrice] = useState<number>(60000);

  useEffect((): void => {
    const warehouseOptionsUE: DefaultOptionType[] =
      Warehouse.warehouses
        ?.filter((warehouse: IWarehouseDetail): boolean => warehouse.isActive)
        .map(
          (warehouse: IWarehouseDetail): DefaultOptionType => ({
            label: warehouse.name,
            value: warehouse.uuid,
          }),
        ) || [];

    setWarehouseOptions(warehouseOptionsUE);
  }, [Warehouse.warehouses, Warehouse.updatedAt]);

  return (
    <Space size={16}>
      <Space size={16} direction="vertical">
        <Select
          style={{ width: 200 }}
          placeholder="Select warehouse"
          showSearch
          optionFilterProp="children"
          options={warehouseOptions}
          onChange={(value: string): void => {
            setWarehouseUuid(value);
          }}
        />
      </Space>

      <Space size={16} direction="vertical">
        <Input
          type="number"
          step="0.001"
          addonBefore="Minimal weight"
          value={minimalWeight}
          addonAfter="kg"
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setMinimalWeight(Number(event.target.value));
          }}
        />

        <Input
          type="number"
          step="0.001"
          addonBefore="Maximal weight"
          value={maximalWeight}
          addonAfter="kg"
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setMaximalWeight(Number(event.target.value));
          }}
        />
      </Space>

      <Space size={16} direction="vertical">
        <Input
          type="number"
          step="0.001"
          addonBefore="Minimal price"
          value={minimalPrice}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setMinimalPrice(Number(event.target.value));
          }}
        />

        <Input
          type="number"
          step="0.001"
          addonBefore="Maximal price"
          value={maximalPrice}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setMaximalPrice(Number(event.target.value));
          }}
        />
      </Space>

      <Space size={16} direction="vertical">
        <Button
          type="primary"
          disabled={!warehouseUuid}
          onClick={(): void => {
            if (!warehouseUuid) {
              return;
            }

            dispatch(
              cb(
                warehouseUuid,
                minimalWeight,
                maximalWeight,
                minimalPrice,
                maximalPrice,
              ),
            );
          }}
        >
          <ReloadOutlined />
        </Button>
      </Space>
    </Space>
  );
}

const mapStateToProps = ({
  Warehouse,
}: RootState): {
  Warehouse: IWarehouseState;
} => ({
  Warehouse,
});

export default connect(mapStateToProps)(WarehouseProductFilter);
