import { type ILanguageType } from '@mahawi/eshop-common';
import { findLanguage } from '@mahawi/eshop-common/dist/src/translation';
import { Menu, Select } from 'antd';
import { type MenuItemType } from 'antd/es/menu/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router';
import { type Dispatch } from 'redux';

import { type RootState } from '../reducers';
import { setLanguage } from '../reducers/language/actions';
import { type ILanguageState } from '../reducers/language/types';
import { type IUserState } from '../reducers/user/types';
import Link from './link';

const { Option } = Select;

function Topbar({
  User,
  Language,
  dispatch,
}: {
  User: IUserState;
  Language: ILanguageState;
  dispatch: Dispatch;
}): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const [menuItems, setMenuItems] = useState<MenuItemType[]>([]);

  const onChangeSelect = useCallback(
    (languageTypeCode: string): void => {
      const languageType: ILanguageType | null = findLanguage(
        Language.languages,
        languageTypeCode,
      );

      if (languageType) {
        dispatch(setLanguage(languageType));
        searchParams.set('languageTypeCode', languageType.code);
        setSearchParams(searchParams);
      }
    },
    [dispatch, Language.languages, searchParams, setSearchParams],
  );

  useEffect(() => {
    const languageTypeCode: string | null =
      searchParams.get('languageTypeCode');

    if (!languageTypeCode) {
      return;
    }

    const languageType: ILanguageType | null = findLanguage(
      Language.languages,
      languageTypeCode,
    );

    if (languageType) {
      dispatch(setLanguage(languageType));
    }
  }, [searchParams, setSearchParams, Language.languages, dispatch]);

  useEffect((): void => {
    let menuItemsUE: MenuItemType[] = [
      {
        key: 'home',
        label: <Link to="/" label="Home" />,
      },
    ];

    if (User.isLoggedIn) {
      menuItemsUE = menuItemsUE.concat([
        {
          key: 'dashboard',
          label: <Link to="/dashboard" label="Dashboard" />,
        },
        {
          key: 'offer',
          label: <Link to="/offer" label="Offer" />,
        },
        {
          key: 'orders',
          label: <Link to="/orders" label="Orders" />,
        },
        {
          key: 'messenger',
          label: <Link to="/messenger" label="Messenger" />,
        },
        {
          key: 'discount',
          label: <Link to="/discount" label="Discount" />,
        },
        {
          key: 'downloader',
          label: <Link to="/downloader" label="Downloader" />,
        },
        {
          key: 'ebay',
          label: <Link to="/ebay" label="eBay" />,
        },
        {
          key: 'report',
          label: <Link to="/report" label="Report" />,
        },
        {
          key: 'settings',
          label: <Link to="/settings" label="Settings" />,
        },
        {
          key: 'search',
          label: <Link to="/search" label="Search" />,
        },
        {
          key: 'logout',
          label: <Link to="/logout" label="Logout" />,
        },
      ]);
    } else {
      menuItemsUE = menuItemsUE.concat([
        {
          key: 'login',
          label: <Link to="/login" label="Login" />,
        },
      ]);
    }

    menuItemsUE = menuItemsUE.concat([
      {
        key: 'language',
        label: (
          <Select
            placeholder="Select language"
            onChange={(value: string): void => onChangeSelect(value)}
            value={Language.languageType?.code}
          >
            {Language.languages?.map(
              (language: ILanguageType): React.ReactElement => (
                <Option value={language.code} key={language.code}>
                  {`${language.name} (${language.code})`}
                </Option>
              ),
            )}
          </Select>
        ),
      },
    ]);

    setMenuItems(menuItemsUE);
  }, [User.isLoggedIn, Language, dispatch, onChangeSelect]);

  return <Menu mode="horizontal" items={menuItems} />;
}

const mapStateToProps = ({ User, Language }: RootState) => ({
  User,
  Language,
});

export default connect(mapStateToProps)(Topbar);
