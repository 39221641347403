import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../../reducers';
import { propertyAdd } from '../../reducers/properties/actions';
import { type IPropertiesState } from '../../reducers/properties/types';
import Link from '../link';

interface IForm {
  code: string;
}

function PropertyAdd({
  dispatch,
  Properties,
}: {
  dispatch: Dispatch;
  Properties: IPropertiesState;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();
  const [newPropertyElement, setNewPropertyElement] = useState<
    React.ReactElement | undefined
  >(undefined);

  const onFinish = useCallback(
    ({ code }: IForm): void => {
      dispatch(propertyAdd(code));
    },
    [dispatch],
  );

  useEffect((): void => {
    if (
      Properties.inProcess === false &&
      Properties.property.code === undefined
    ) {
      form.resetFields();
    }
  }, [Properties.inProcess, Properties.property.code, form]);

  useEffect((): void => {
    let newPropertyElementUE: React.ReactElement | undefined;

    if (Properties.property.code) {
      newPropertyElementUE = (
        <Link
          to={`/property/${Properties.property.code}`}
          openInNewTab
          label={`Created new property ${Properties.property.code}`}
        />
      );
    }

    setNewPropertyElement(newPropertyElementUE || undefined);
  }, [Properties.property.code]);

  return (
    <Space size={16} align="start">
      <Form form={form} onFinish={(values: IForm): void => onFinish(values)}>
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
            },
            {
              type: 'string',
              min: 3,
            },
          ]}
          label="Property CODE"
        >
          <Input
            size="large"
            placeholder="Create new property CODE"
            prefix={<EditOutlined />}
            type="string"
            disabled={Properties.inProcess}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>

      {newPropertyElement}
    </Space>
  );
}

const mapStateToProps = ({
  Properties,
}: RootState): { Properties: IPropertiesState } => ({ Properties });

export default connect(mapStateToProps)(PropertyAdd);
