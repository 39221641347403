import { SearchOutlined } from '@ant-design/icons';
import { removeDiacritics } from '@mahawi/eshop-common/dist/src/remove-diacritics';
import { type TableColumnType } from 'antd';
import { Button, Input, Space } from 'antd';
import { type FilterDropdownProps } from 'antd/es/table/interface';
import React, { type ChangeEvent } from 'react';

export function getColumnSearch<T>(dataIndex: string): TableColumnType<T> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps): React.ReactElement => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>): void =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={(): void => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={(): void => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={(): void => clearFilters && clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean): React.ReactElement => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: T): boolean => {
      const recordValue: string = removeDiacritics(record[dataIndex])
        .trim()
        .toLocaleLowerCase();
      const valuesToSearch: string[] = removeDiacritics(value)
        .trim()
        .toLocaleLowerCase()
        .split(' ');

      return valuesToSearch.every((searchValue: string): boolean =>
        recordValue.includes(searchValue),
      );
    },
  };
}
