import { EditOutlined } from '@ant-design/icons';
import { type RootState } from 'admin/react/reducers';
import { ColorPicker, Form, Input, Row, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { tagsAdd } from '../../reducers/tags/actions';
import { type ITagsState } from '../../reducers/tags/types';

interface IForm {
  name: string;
  color: string;
}

const randomHex = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

function TagAdd({
  dispatch,
  Tags,
}: {
  dispatch: Dispatch;
  Tags: ITagsState;
}): React.ReactElement {
  const [defaultColor] = useState(randomHex);

  const onFinish = useCallback(
    ({ name, color }: IForm): void => {
      dispatch(tagsAdd(name, color));
    },
    [dispatch],
  );

  const [form] = Form.useForm<IForm>();

  return (
    <Form
      initialValues={{ name: '', color: defaultColor }}
      form={form}
      onFinish={(values: IForm): void => onFinish(values)}
    >
      <Row gutter={[0, 24]}>
        <Space align="start">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
              {
                type: 'string',
                min: 3,
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Create new product with name"
              prefix={<EditOutlined />}
              type="string"
              disabled={Tags.inProcess}
            />
          </Form.Item>

          <Form.Item
            name="color"
            rules={[
              {
                required: true,
              },
              {
                type: 'string',
                min: 3,
              },
            ]}
          >
            <Input type="hidden" />
          </Form.Item>

          <ColorPicker
            defaultValue={defaultColor}
            size="large"
            onChange={(_value, hex: string): void =>
              form.setFieldValue('color', hex)
            }
          />
        </Space>
      </Row>
    </Form>
  );
}

const mapStateToProps = ({ Tags }: RootState) => ({
  Tags,
});

export default connect(mapStateToProps)(TagAdd);
