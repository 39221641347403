import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  getLowerCaseLanguageCode,
  getTranslation,
} from '@mahawi/eshop-common/dist/src/translation';
import {
  type ICurrencyPrice,
  type ICurrencyType,
  type IProduct,
  type IProductEbay,
  type IProductFeatureFlag,
  type IWarehouse,
} from '@mahawi/eshop-common/dist/src/types';
import { Tag } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import React, { Key } from 'react';

import ExternalLink from '../components/external-link';
import Link from '../components/link';
import { type IConfigState } from '../reducers/config/types';
import { type ICurrencyState } from '../reducers/currency/types';
import { type IFeatureFlagsState } from '../reducers/feature-flags/types';
import { type ILanguageState } from '../reducers/language/types';
import { type IProductsState } from '../reducers/products/types';
import { coloredRenderOfNumberInTable } from './colored-render-of-number-in-table';
import { featureFlagTags } from './feature-flag-tags';
import { getTableFeatureFlagsColumnFilters } from './table-feature-flags-column';
import { getColumnSearch } from './table-get-column-search';

export interface ProductTableColumn extends IProduct {
  key: string;
  name: string;
  priceEUR: number | undefined;
}

export function getDataSourceForProductsTable({
  Products,
  Language,
}: {
  Products: IProductsState;
  Language: ILanguageState;
}): ProductTableColumn[] {
  if (!Products.products) {
    return [];
  }

  const dataSource: ProductTableColumn[] = Products.products.map(
    ({
      brand,
      gtin,
      stock,
      mpn,
      names,
      uuid,
      productsEbay,
      photoUUIDs,
      relatedProductsCount,
      prices,
      primaryWarehouse,
      featureFlags,
      category,
      isActive,
    }: IProduct): ProductTableColumn => {
      const productLocaNameLocalized: string | null = getTranslation(
        names,
        Language.languageType,
      );

      const price: ICurrencyPrice | undefined = prices.find(
        (p: ICurrencyPrice): boolean => p.isoCode === 'EUR',
      );

      return {
        key: uuid,
        brand,
        name: productLocaNameLocalized || '---',
        gtin,
        stock,
        mpn,
        uuid,
        productsEbay,
        photoUUIDs,
        relatedProductsCount,
        priceEUR: price?.price,
        primaryWarehouse,
        featureFlags,
        category,
        names,
        prices,
        isActive,
      };
    },
  );

  return dataSource;
}

export function getProductsTableColumns({
  dataSource,
  Config,
  Currency,
  Language,
  FeatureFlags,
}: {
  dataSource: ProductTableColumn[];
  Config: IConfigState;
  Currency: ICurrencyState;
  Language: ILanguageState;
  FeatureFlags: IFeatureFlagsState;
}): ColumnsType<object> {
  const currencyTypeEUR: ICurrencyType | undefined = Currency.currencies.find(
    (c: ICurrencyType): boolean => c.isoCode === 'EUR',
  );

  if (!currencyTypeEUR) {
    throw new Error('EUR currency not found');
  }

  const columnFilterItemPrimaryWarehouse: ColumnFilterItem[] = [
    ...new Set(
      dataSource.map(
        ({ primaryWarehouse }: ProductTableColumn): string | undefined =>
          primaryWarehouse?.name,
      ),
    ),
  ]
    .filter(notEmpty)
    .map((name: string) => ({
      text: name,
      value: name,
    }));

  columnFilterItemPrimaryWarehouse.push({
    text: 'With primary warehouse',
    value: 'withPrimaryWarehouse',
  });

  columnFilterItemPrimaryWarehouse.push({
    text: 'Without primary warehouse',
    value: 'withoutPrimaryWarehouse',
  });

  const columns: ColumnsType<object> = [
    {
      title: 'Brand',
      dataIndex: ['brand', 'name'],
      sorter: (a: ProductTableColumn, b: ProductTableColumn) => {
        if (a.brand?.name && b.brand?.name) {
          return a.brand.name.localeCompare(b.brand.name);
        }

        return 0;
      },
      filterSearch: true,
      filters: [
        ...new Set(
          dataSource.map(
            ({ brand }: ProductTableColumn): string | undefined => brand?.name,
          ),
        ),
      ]
        .filter(notEmpty)
        .map((name: string) => ({
          text: name,
          value: name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean =>
        record.brand?.name === value,
      width: '5%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record: ProductTableColumn): React.ReactElement => (
        <Link to={`/product/${record.uuid}`} label={text} openInNewTab />
      ),
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        a.name.localeCompare(b.name),
      width: '20%',
      ...getColumnSearch('name'),
    },
    {
      title: 'Price EUR',
      dataIndex: 'priceEUR',
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        (a.priceEUR || 0) - (b.priceEUR || 0),
      render: (priceEUR: number | undefined): string =>
        priceEUR ? formatCurrency(priceEUR, currencyTypeEUR) : '---',
      filterSearch: true,
      filters: [
        {
          text: 'With price',
          value: 'withPrice',
        },
        {
          text: 'Without price',
          value: 'withoutPrice',
        },
      ],
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean => {
        if (value === 'withPrice') {
          return !!record.priceEUR;
        }

        if (value === 'withoutPrice') {
          return !record.priceEUR;
        }

        return false;
      },
      width: '10%',
    },
    {
      title: 'MPN',
      dataIndex: 'mpn',
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        `${a.mpn}`.localeCompare(`${b.mpn}`),
      filterSearch: true,
      filters: [
        ...new Set(
          dataSource.map(
            ({ mpn }: ProductTableColumn): string | undefined => mpn,
          ),
        ),
      ]
        .filter((mpn: string | undefined): mpn is string => !!mpn)
        .map((name: string) => ({
          text: name,
          value: name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean =>
        record.mpn === value,
      width: '10%',
    },
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        (a.gtin || '').localeCompare(b.gtin || ''),
      filterSearch: true,
      filters: [
        ...new Set(
          dataSource.map(
            ({ gtin }: ProductTableColumn): string | undefined => gtin,
          ),
        ),
      ]
        .filter(notEmpty)
        .map((name: string) => ({
          text: name,
          value: name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean =>
        record.gtin === value,
      width: '10%',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (category: { uuids: string[] }): number => category.uuids.length,
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        a.category.uuids.length - b.category.uuids.length,
      filterSearch: true,
      filters: [
        {
          text: 'With category',
          value: 'withCategory',
        },
        {
          text: 'Without category',
          value: 'withoutCategory',
        },
      ],
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean => {
        if (value === 'withCategory') {
          return record.category.uuids.length > 0;
        }

        if (value === 'withoutCategory') {
          return record.category.uuids.length === 0;
        }

        return false;
      },
      width: '5%',
    },
    {
      title: 'Photos',
      dataIndex: 'photoUUIDs',
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        a.photoUUIDs.length - b.photoUUIDs.length,
      filterSearch: true,
      filters: [
        {
          text: 'With photos',
          value: 'withPhotos',
        },
        {
          text: 'Without photos',
          value: 'withoutPhotos',
        },
      ],
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean => {
        if (value === 'withPhotos') {
          return record.photoUUIDs.length > 0;
        }

        if (value === 'withoutPhotos') {
          return record.photoUUIDs.length === 0;
        }

        return false;
      },
      render: (photoUUIDs: string[]): number => photoUUIDs.length,
      width: '5%',
    },
    {
      title: 'Related products',
      dataIndex: 'relatedProductsCount',
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        a.relatedProductsCount - b.relatedProductsCount,
      filterSearch: true,
      filters: [
        {
          text: 'With related products',
          value: 'withRelatedProducts',
        },
        {
          text: 'Without related products',
          value: 'withoutRelatedProducts',
        },
      ],
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean => {
        if (value === 'withRelatedProducts') {
          return record.relatedProductsCount > 0;
        }

        if (value === 'withoutRelatedProducts') {
          return record.relatedProductsCount === 0;
        }

        return false;
      },
      render: coloredRenderOfNumberInTable,
      width: '5%',
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        a.stock - b.stock,
      render: coloredRenderOfNumberInTable,
      filterSearch: true,
      filters: [
        {
          text: 'In stock',
          value: 'inStock',
        },
        {
          text: 'Full stock',
          value: 'fullStock',
        },
        {
          text: 'Low stock',
          value: 'lowStock',
        },
        {
          text: 'Out of stock',
          value: 'outOfStock',
        },
      ],
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean => {
        if (value === 'inStock') {
          return record.stock > 0;
        }

        if (value === 'fullStock') {
          return record.stock >= 5;
        }

        if (value === 'lowStock') {
          return record.stock > 0 && record.stock < 5;
        }

        if (value === 'outOfStock') {
          return record.stock === 0;
        }

        return false;
      },
      width: '5%',
    },
    {
      title: 'Primary warehouse',
      dataIndex: 'primaryWarehouse',
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        a.primaryWarehouse?.name.localeCompare(
          b.primaryWarehouse?.name || '',
        ) || 0,
      filterSearch: true,
      filters: columnFilterItemPrimaryWarehouse,
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean => {
        if (value === 'withoutPrimaryWarehouse') {
          return !record.primaryWarehouse;
        }

        if (value === 'withPrimaryWarehouse') {
          return !!record.primaryWarehouse;
        }

        return record.primaryWarehouse?.name === value;
      },
      render: (primaryWarehouse: IWarehouse | undefined): string => {
        return primaryWarehouse ? `${primaryWarehouse.name}` : '---';
      },
      width: '10%',
    },
    {
      title: 'Feature flags',
      dataIndex: 'featureFlags',
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        a.featureFlags.length - b.featureFlags.length,
      filterSearch: true,
      filters: getTableFeatureFlagsColumnFilters({ dataSource, FeatureFlags }),
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean => {
        if (value === 'withFeatureFlags') {
          return record.featureFlags.length > 0;
        }

        if (value === 'withoutFeatureFlags') {
          return record.featureFlags.length === 0;
        }

        return record.featureFlags.some(
          (productFeatureFlag: IProductFeatureFlag): boolean => {
            return productFeatureFlag.code === value;
          },
        );
      },
      render: (
        productFeatureFlags: IProductFeatureFlag[],
      ): React.ReactElement[] =>
        featureFlagTags({ productFeatureFlags, FeatureFlags }),
      width: '5%',
    },
    {
      title: 'Ebay',
      dataIndex: 'productsEbay',
      sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
        a.productsEbay.length - b.productsEbay.length,
      render: (productsEbay: IProductEbay[]): React.ReactElement[] =>
        productsEbay.map(
          (productEbay: IProductEbay): React.ReactElement => (
            <Tag key={productEbay.marketplace.code}>
              {productEbay.marketplace.name}
            </Tag>
          ),
        ),
      filterSearch: true,
      filters: [
        {
          text: 'With ebay',
          value: 'withEbay',
        },
        {
          text: 'Without ebay',
          value: 'withoutEbay',
        },
      ],
      onFilter: (value: boolean | Key, record: ProductTableColumn): boolean => {
        if (value === 'withEbay') {
          return record.productsEbay.length > 0;
        }

        if (value === 'withoutEbay') {
          return record.productsEbay.length === 0;
        }

        return false;
      },
      width: '5%',
    },
    {
      title: 'Actions',
      render: (_text, record: ProductTableColumn): React.ReactElement => (
        <ExternalLink
          to={`${
            Config.url
          }/${getLowerCaseLanguageCode(Language.languageType)}/product/${record.uuid}`}
          ariaLabel="Open product in new tab"
        />
      ),
      width: '5%',
    },
  ];

  return columns;
}
