import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { type CSSProperties } from 'react';

import { ISortableListItem } from './types';

export function Item({
  record,
  id,
  ItemTemplate,
}: {
  record: ISortableListItem;
  id: UniqueIdentifier;
  ItemTemplate: (attributes) => React.ReactElement;
}): React.ReactElement {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const itemStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
    // height: 30,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
    border: '1px solid gray',
    borderRadius: 5,
    marginBottom: 5,
    userSelect: 'none',
    cursor: 'grab',
    boxSizing: 'border-box',
  };

  return (
    <div style={itemStyle} ref={setNodeRef} {...attributes} {...listeners}>
      <ItemTemplate id={id} record={record} />
    </div>
  );
}
