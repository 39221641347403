import { GlobalOutlined } from '@ant-design/icons';
import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import { round } from '@mahawi/eshop-common/dist/src/round';
import {
  getLowerCaseLanguageCode,
  getTranslation,
} from '@mahawi/eshop-common/dist/src/translation';
import {
  type IBasketProduct,
  type IOrderCurrencyType,
  type IOrderProduct,
} from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { Skeleton, Space, Table, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type IConfigState } from '../../reducers/config/types';
import { type ILanguageState } from '../../reducers/language/types';
import CopyToClipboard from '../copy-to-clipboard';
import Link from '../link';

interface DataType {
  key: string;
  count: number;
  name: string;
  uuid: string;
  price: number;
  totalGoodsWeight: number;
}

const { Text } = Typography;

function ProductTable({
  products,
  Language,
  Config,
  currencyType,
  withCopyLinks = false,
}: {
  products: IOrderProduct[] | IBasketProduct[] | undefined;
  Language: ILanguageState;
  Config: IConfigState;
  currencyType: IOrderCurrencyType;
  withCopyLinks?: boolean;
}): React.ReactElement {
  const [dataSource, setDataSource] = useState<DataType[] | undefined>([]);
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
  const [totalGoodsWeight, setTotalGoodsWeight] = useState<number>(0);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);

  useEffect((): void => {
    if (!products) {
      return;
    }

    const dataSourceUE = products.map((product) => {
      const name: string | null = getTranslation(
        product.names,
        Language.languageType,
      );

      const price: number =
        product.price ||
        product.prices.find(
          ({ isoCode }): boolean => isoCode === currencyType.isoCode,
        ).price;

      return {
        key: product.uuid,
        count: product.count,
        name: name || '---',
        uuid: product.uuid,
        price,
        totalGoodsWeight: product.goodsWeight,
      };
    });

    const columnsUE: ColumnsType<DataType> = [
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        render: (count: number): React.ReactElement => (
          <Space size={16} align="baseline">
            <Text strong>{count}</Text>
            {withCopyLinks && (
              <CopyToClipboard text={String(count)} size="small" type="link" />
            )}
          </Space>
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (value: string, record: DataType): React.ReactElement => (
          <Space size={16} align="baseline">
            <Link to={`/product/${record.uuid}`} label={value} openInNewTab />

            {withCopyLinks && (
              <CopyToClipboard text={value} size="small" type="link" />
            )}

            <a
              href={`${
                Config.url
              }/${getLowerCaseLanguageCode(Language.languageType)}/product/${
                record.uuid
              }`}
              target="_blank"
              rel="noreferrer noopener nofollow"
              aria-label="Open product in new tab"
            >
              <GlobalOutlined />
            </a>
          </Space>
        ),
      },
      {
        title: 'Price per unit with VAT',
        dataIndex: 'price',
        key: 'price',
        render: (price: number): React.ReactElement => (
          <Space size={16} align="baseline">
            <Text strong>{formatCurrency(price, currencyType)}</Text>
            {withCopyLinks && (
              <CopyToClipboard text={String(price)} size="small" type="link" />
            )}
          </Space>
        ),
      },
      {
        title: 'Total goods weight [kg]',
        dataIndex: 'totalGoodsWeight',
        key: 'totalGoodsWeight',
      },
    ];

    setDataSource(dataSourceUE);
    setColumns(columnsUE);

    setTotalGoodsWeight(
      round(
        dataSourceUE.reduce(
          (acc: number, { totalGoodsWeight }: DataType): number =>
            acc + totalGoodsWeight,
          0,
        ),
        4,
      ),
    );

    setTotalItemsCount(
      dataSourceUE.reduce(
        (acc: number, { count }: DataType): number => acc + count,
        0,
      ),
    );
  }, [
    products,
    Language.languageType?.code,
    currencyType.isoCode,
    Config.url,
    withCopyLinks,
    currencyType,
  ]);

  if (!dataSource) {
    return <Skeleton active />;
  }

  if (dataSource.length === 0) {
    return <Typography.Paragraph>Empty</Typography.Paragraph>;
  }

  return (
    <Space size={8} direction="vertical" style={{ width: '100%' }}>
      <Table dataSource={dataSource} columns={columns} />
      <Space size={16} align="start">
        <Typography.Text>
          Total goods weight: {totalGoodsWeight} kg
        </Typography.Text>
        <CopyToClipboard
          text={String(totalGoodsWeight)}
          size="small"
          type="link"
        />
      </Space>
      <Space size={16} align="start">
        <Typography.Text>Total items count: {totalItemsCount}</Typography.Text>
        <CopyToClipboard
          text={String(totalItemsCount)}
          size="small"
          type="link"
        />
      </Space>
    </Space>
  );
}

const mapStateToProps = ({ Language, Config }: RootState) => ({
  Language,
  Config,
});

export default connect(mapStateToProps)(ProductTable);
