import { ReloadOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Switch } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import LanguageButtons from '../components/language-buttons';
import ProductAdd from '../components/product/product-add';
import Table from '../components/table';
import {
  getDataSourceForProductsTable,
  getProductsTableColumns,
  type ProductTableColumn,
} from '../fragments/products-table-columns';
import { getUpdatedAtMessage } from '../fragments/updated-at-message';
import { type RootState } from '../reducers';
import { type IConfigState } from '../reducers/config/types';
import { type ICurrencyState } from '../reducers/currency/types';
import { type IFeatureFlagsState } from '../reducers/feature-flags/types';
import { type ILanguageState } from '../reducers/language/types';
import { productsLoadAll } from '../reducers/products/actions';
import { type IProductsState } from '../reducers/products/types';

function ProductsContainer({
  dispatch,
  Config,
  Products,
  Language,
  Currency,
  FeatureFlags,
}: {
  dispatch: Dispatch;
  Config: IConfigState;
  Products: IProductsState;
  Language: ILanguageState;
  Currency: ICurrencyState;
  FeatureFlags: IFeatureFlagsState;
}): React.ReactElement {
  const [dataSource, setDataSource] = useState<ProductTableColumn[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);
  const [barElement, setBarElement] = useState<
    React.ReactElement | undefined
  >();
  const [isActiveProducts, setIsActiveProducts] = useState<boolean>(true);

  useEffect((): void => {
    const dataSourceUE: ProductTableColumn[] = getDataSourceForProductsTable({
      Products,
      Language,
    });

    const columnsUE: ColumnsType<object> = getProductsTableColumns({
      dataSource: dataSourceUE,
      Config,
      Currency,
      Language,
      FeatureFlags,
    });

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [Config, Language, Products, Products.updatedAt]);

  useEffect((): void => {
    const barElementUE: React.ReactElement = (
      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <Switch
          checkedChildren="Active products"
          unCheckedChildren="Disabled products"
          checked={isActiveProducts}
          onChange={(checked: boolean): void => {
            setIsActiveProducts(checked);
          }}
        />

        <Button
          type={!Products.products ? 'primary' : 'default'}
          onClick={(): void => {
            dispatch(productsLoadAll(isActiveProducts));
          }}
          disabled={Products.inProcess}
        >
          Reload all <ReloadOutlined />
        </Button>

        {getUpdatedAtMessage(
          Products.updatedAt !== undefined,
          Products.updatedAt,
        )}
      </Space>
    );

    setBarElement(barElementUE);
  }, [
    Products.inProcess,
    Products.updatedAt,
    Products.products,
    dispatch,
    isActiveProducts,
  ]);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      {barElement}

      <LanguageButtons />

      <ProductAdd />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Products.inProcess && !Products.products}
      />
    </Space>
  );
}

const mapStateToProps = ({
  Products,
  Language,
  Config,
  Currency,
  FeatureFlags,
}: RootState) => ({
  Products,
  Language,
  Config,
  Currency,
  FeatureFlags,
});

export default connect(mapStateToProps)(ProductsContainer);
