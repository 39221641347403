import { type IAdminCategory } from '@mahawi/eshop-common';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import { type RootState } from 'admin/react/reducers';
import { FormInstance, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type ICategoriesState } from '../reducers/categories/types';
import { type ILanguageState } from '../reducers/language/types';
import { getFilterOption, getFilterSort } from './select-filter';

function SelectCategoryOptionsComponent({
  Categories,
  fieldName,
  form,
  isDisabled = false,
  Language,
  mode = undefined,
}: {
  Categories: ICategoriesState;
  fieldName: string;
  form: FormInstance;
  isDisabled?: boolean;
  Language: ILanguageState;
  mode?: 'multiple' | 'tags';
}): React.ReactElement {
  const [categories, setCategories] = useState<React.ReactElement[]>([]);

  useEffect((): void => {
    if (!Categories.categories) {
      return;
    }

    const categoriesUE: React.ReactElement[] = Categories.categories.map(
      ({ uuid, names }: IAdminCategory): React.ReactElement => {
        const name: string | null = getTranslation(
          names,
          Language.languageType,
        );

        return (
          <Select.Option value={uuid} key={uuid}>
            {name ?? uuid}
          </Select.Option>
        );
      },
    );

    setCategories(categoriesUE);
  }, [
    Categories.categories,
    Language.languageType?.code,
    Categories.updatedAt,
  ]);

  return (
    <Select
      value={form.getFieldValue(fieldName)}
      onChange={(value: string): void => form.setFieldValue(fieldName, value)}
      mode={mode}
      allowClear
      showSearch
      placeholder={
        mode === 'multiple' ? 'Select categories' : 'Select category'
      }
      disabled={isDisabled}
      autoClearSearchValue={false}
      filterOption={getFilterOption}
      filterSort={getFilterSort}
    >
      {categories.map(
        (categoryEl: React.ReactElement): React.ReactElement => categoryEl,
      )}
    </Select>
  );
}

const mapStateToProps = ({ Categories, Language, Product }: RootState) => ({
  Categories,
  Language,
  Product,
});

export default connect(mapStateToProps)(SelectCategoryOptionsComponent);
