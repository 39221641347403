import { LockOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { type Dispatch } from 'redux';

import { type RootState } from '../reducers';
import { login } from '../reducers/user/actions';
import { type IUserState } from '../reducers/user/types';

interface IForm {
  email: string;
  password: string;
}

const onFinish = ({ email, password }: IForm, dispatch: Dispatch): void => {
  dispatch(login(email, password));
};

function LoginContainer({
  dispatch,
  User,
}: {
  dispatch: Dispatch;
  User: IUserState;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  if (User.isLoggedIn) {
    return <Navigate to="/home" replace />;
  }

  return (
    <Form form={form} onFinish={(values) => onFinish(values, dispatch)}>
      <Row gutter={[0, 24]}>
        <Col span={8} offset={8} className="gutter-row">
          <Divider orientation="left">Fill your information</Divider>
        </Col>

        <Col span={8} offset={8} className="gutter-row">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
              },
              {
                type: 'email',
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Email"
              prefix={<UserOutlined />}
              type="email"
              disabled={User.inProcess}
            />
          </Form.Item>
        </Col>

        <Col span={8} offset={8} className="gutter-row">
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
              },
              {
                type: 'string',
                min: 8,
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Password"
              prefix={<LockOutlined />}
              type="password"
              disabled={User.inProcess}
            />
          </Form.Item>
        </Col>

        <Col span={8} offset={8} className="gutter-row">
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            icon={<LoginOutlined />}
            size="large"
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length ||
              User.inProcess
            }
          >
            Sign in
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = ({ User }: RootState): { User: IUserState } => ({
  User,
});

export default connect(mapStateToProps)(LoginContainer);
