import { Space, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

function HomeContainer(): React.ReactElement {
  return (
    <Space size={16} align="start" direction="vertical">
      <Typography>
        <Title level={2}>Home</Title>
      </Typography>
    </Space>
  );
}

export default HomeContainer;
