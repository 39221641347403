import { stringToColor } from '@mahawi/eshop-common/dist/src/string-to-color';
import {
  EFeatureFlag,
  type IFeatureFlag,
  type IProductFeatureFlag,
} from '@mahawi/eshop-common/dist/src/types';
import { List, Tag, Tooltip } from 'antd';
import React from 'react';

import { type IFeatureFlagsState } from '../reducers/feature-flags/types';

const getFeatureFlagElement = ({
  code,
  ffColor,
  ff,
  productUUID,
  productFeatureFlag,
  onClose,
}: {
  code: EFeatureFlag;
  ffColor: string;
  ff: IFeatureFlag;
  productUUID?: string;
  productFeatureFlag: IProductFeatureFlag;
  onClose?: (
    productUUID: string,
    productFeatureFlag: IProductFeatureFlag,
  ) => void;
}): React.ReactElement => {
  return (
    <Tag
      key={code}
      color={ffColor}
      closable={
        !!onClose &&
        (productFeatureFlag.isVirtual === undefined ||
          productFeatureFlag.isVirtual === false)
      }
      onClose={(): void =>
        !!onClose && productUUID
          ? onClose(productUUID, productFeatureFlag)
          : undefined
      }
    >
      {ff.name}
    </Tag>
  );
};

export const featureFlagTags = ({
  productFeatureFlags,
  FeatureFlags,
  productUUID,
  onClose,
}: {
  productFeatureFlags: IProductFeatureFlag[];
  FeatureFlags: IFeatureFlagsState;
  productUUID?: string;
  onClose?: (
    productUUID: string,
    productFeatureFlag: IProductFeatureFlag,
  ) => void;
}): React.ReactElement[] => {
  const featureFlagElements: React.ReactElement[] = productFeatureFlags.map(
    (productFeatureFlag: IProductFeatureFlag): React.ReactElement => {
      const code: EFeatureFlag = productFeatureFlag.code;
      const ff: IFeatureFlag | undefined = FeatureFlags.featureFlags?.find(
        (f: IFeatureFlag): boolean => f.code === code,
      );

      if (!ff) {
        return <Tag key={code}>{code}</Tag>;
      }

      const ffColor: string = ff.color || stringToColor(ff.name);

      if (productFeatureFlag.value) {
        return (
          <Tooltip
            key={code}
            title={(): React.ReactElement => {
              const items: string[] = [];

              if (productFeatureFlag.value?.languageTypesCodes) {
                items.push(
                  `Language types codes: ${productFeatureFlag.value?.languageTypesCodes?.join(', ') || ''}`,
                );
              }

              if (productFeatureFlag.value?.warehouse) {
                items.push(
                  `Warehouse: ${productFeatureFlag.value?.warehouse.uuid}`,
                );
              }

              if (productFeatureFlag.value?.messages) {
                productFeatureFlag.value?.messages.forEach(
                  (message: string): void => {
                    items.push(`Message: ${message}`);
                  },
                );
              }

              return (
                <List
                  dataSource={items}
                  renderItem={(item: string): React.ReactElement => (
                    <List.Item style={{ color: 'white' }}>{item}</List.Item>
                  )}
                />
              );
            }}
            placement="bottom"
          >
            {getFeatureFlagElement({
              code,
              ffColor,
              ff,
              productUUID,
              productFeatureFlag,
              onClose,
            })}
          </Tooltip>
        );
      }

      return getFeatureFlagElement({
        code,
        ffColor,
        ff,
        productUUID,
        productFeatureFlag,
        onClose,
      });
    },
  );

  return featureFlagElements;
};
