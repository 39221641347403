import React from 'react';
import { Navigate, useLocation } from 'react-router';

function PrivateRoute({
  children,
  isLoggedIn,
}: {
  children: React.ReactElement;
  isLoggedIn: boolean;
}): React.ReactElement {
  const location = useLocation();

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default PrivateRoute;
