import { ReloadOutlined } from '@ant-design/icons';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  type IReportProductLanguage,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { Button, Divider, Space, Spin, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';
import slugify from 'slugify';

import Link from '../components/link';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { namesDescriptionsSlugsUpdate } from '../reducers/product/actions';
import { reportProductLanguagesLoad } from '../reducers/report/actions';
import { type IReportState } from '../reducers/report/types';

const { Title } = Typography;

interface DataType extends IReportProductLanguage {
  key: string;
}

function openGoogleTranslateLink(
  fromLanguageCode: string,
  toLanguageCode: string,
  text: string,
): void {
  const gtLink = new URL('https://translate.google.com/');
  gtLink.searchParams.append('sl', fromLanguageCode.toLowerCase());
  gtLink.searchParams.append('tl', toLanguageCode.toLowerCase());
  gtLink.searchParams.append('text', text.trim());
  gtLink.searchParams.append('op', 'translate');

  window.open(gtLink.toString(), '_blank');
}

async function startAutomat(
  dataSource: DataType[],
  dispatch: Dispatch,
  setRunning: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> {
  setRunning(true);

  for (const record of dataSource) {
    const names: ITranslation[] = [];
    const descriptions: ITranslation[] = [];
    const slugs: ITranslation[] = [];

    for (const languageTypeCodeMissing of record.languageType.missingCodes) {
      const [translationName] = record.translation.names.filter(notEmpty);
      const [translationDescription] =
        record.translation.descriptions.filter(notEmpty);

      if (translationName && translationName.value.length >= 2) {
        await new Promise<void>((resolve): void => {
          window.addEventListener(
            'focus',
            async (): Promise<void> => {
              const text: string = await navigator.clipboard.readText();
              const tt: string = text.trim();

              if (tt) {
                names.push({
                  code: languageTypeCodeMissing,
                  value: tt,
                });

                slugs.push({
                  code: languageTypeCodeMissing,
                  value: slugify(tt, { lower: true, strict: true }),
                });
              }

              resolve();
            },
            { once: true },
          );

          openGoogleTranslateLink(
            translationName.code,
            languageTypeCodeMissing,
            translationName.value,
          );
        });
      }

      if (translationDescription && translationDescription.value.length >= 2) {
        await new Promise<void>((resolve) => {
          window.addEventListener(
            'focus',
            async (): Promise<void> => {
              const text: string = await navigator.clipboard.readText();
              const tt: string = text.trim();

              if (tt) {
                descriptions.push({
                  code: languageTypeCodeMissing,
                  value: tt,
                });
              }

              resolve();
            },
            { once: true },
          );

          openGoogleTranslateLink(
            translationDescription.code,
            languageTypeCodeMissing,
            translationDescription.value,
          );
        });
      }
    }

    dispatch(
      namesDescriptionsSlugsUpdate(
        record.product.uuid,
        names,
        descriptions,
        slugs,
      ),
    );
  }

  setRunning(false);
}

function ReportProductLanguages({
  dispatch,
  Report,
}: {
  dispatch: Dispatch;
  Report: IReportState;
}): React.ReactElement {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);
  const [isRunning, setRunning] = useState<boolean>(false);

  useEffect((): void => {
    if (!Report.productLanguages) {
      return;
    }

    const columnsUE: ColumnsType<object> = [
      {
        title: 'UUID',
        dataIndex: ['product', 'uuid'],
        render: (_text, record: DataType): React.ReactElement => (
          <Link
            to={`/product/${record.product.uuid}`}
            openInNewTab
            label={record.product.uuid}
          />
        ),
      },
      {
        title: 'Name',
        dataIndex: ['translation', 'names', 0, 'value'],
        render: (_text, record: DataType): string => {
          const [name] = record.translation.names.filter(notEmpty);

          return `[${name?.code}] ${name?.value}`;
        },
      },
      {
        title: 'Missing languages',
        dataIndex: ['languageType', 'missingCodes'],
        render: (_text, record: DataType): string =>
          record.languageType.missingCodes.join(', '),
      },
    ];

    setColumns(columnsUE);

    const dataSourceUE = Report.productLanguages.map((item) => ({
      ...item,
      key: item.product.uuid,
    }));

    setDataSource(dataSourceUE);
  }, [Report.productLanguages]);

  return (
    <>
      <Typography>
        <Title level={2}>Report product languages</Title>
      </Typography>

      <Divider />

      <Space size={16} align="start">
        <Button
          type="primary"
          onClick={(): void => {
            dispatch(reportProductLanguagesLoad());
          }}
          disabled={!Report.productLanguages}
        >
          <ReloadOutlined />
        </Button>

        <Space size={16} align="start">
          <Button
            type="primary"
            onClick={(): void => {
              startAutomat(dataSource, dispatch, setRunning);
            }}
            disabled={
              !Report.productLanguages || isRunning || dataSource.length === 0
            }
          >
            GT Automat
          </Button>
          <Spin size="small" spinning={isRunning} />
        </Space>
      </Space>

      <Divider />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={!Report.productLanguages}
      />
    </>
  );
}

const mapStateToProps = ({ Report }: RootState) => ({ Report });

export default connect(mapStateToProps)(ReportProductLanguages);
