import { ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Form,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { type DescriptionsItemType } from 'antd/es/descriptions';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { type Dispatch } from 'redux';

import WarehouseProductsTable from '../components/downloader/warehouse-products-table';
import SelectCategoryOptions from '../fragments/select-category-options';
import { type RootState } from '../reducers';
import { categoriesLoadAll } from '../reducers/categories/actions';
import {
  downloaderLoadWarehouseCategory,
  downloaderLoadWarehouseProductsByWarehouseCategory,
  downloaderPairWarehouseCategory,
} from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';

interface IForm {
  categoryUUID: string;
}

function DownloaderWarehouseCategory({
  dispatch,
  Downloader,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
}): React.ReactElement {
  const { uuid } = useParams();
  const [formCategory] = Form.useForm<IForm>();

  const onFinishCategory = useCallback(
    (values: IForm, uuid: string | undefined, dispatch: Dispatch): void => {
      if (!uuid) {
        return;
      }

      dispatch(downloaderPairWarehouseCategory(uuid, values.categoryUUID));
    },
    [],
  );

  const reloadAll = useCallback(
    (uuid: string | undefined, dispatch: Dispatch): void => {
      if (uuid) {
        dispatch(downloaderLoadWarehouseCategory(uuid));
        dispatch(downloaderLoadWarehouseProductsByWarehouseCategory(uuid));
      }

      dispatch(categoriesLoadAll());
    },
    [],
  );

  useEffect((): void => {
    reloadAll(uuid, dispatch);
  }, [uuid, dispatch]);

  useEffect((): void => {
    formCategory.setFieldValue(
      'categoryUUID',
      Downloader.warehouse.category?.category?.uuid,
    );
  }, [
    Downloader.warehouse.category,
    Downloader.updatedAt,
    Downloader.inProcess,
    formCategory,
  ]);

  const items: DescriptionsItemType[] = [
    {
      key: '1',
      label: 'Raw Name',
      children: Downloader.warehouse.category?.rawName || '---',
    },
    {
      key: '2',
      label: 'Name',
      children: Downloader.warehouse.category?.name || '---',
    },
    {
      key: '3',
      label: 'Category',
      children: (
        <Form
          form={formCategory}
          disabled={Downloader.inProcess || !uuid}
          onFinish={(values: IForm): void => {
            onFinishCategory(values, uuid, dispatch);
          }}
        >
          <Row>
            <Col span={8}>
              <Form.Item name="categoryUUID">
                <SelectCategoryOptions
                  form={formCategory}
                  fieldName="categoryUUID"
                  isDisabled={Downloader.inProcess}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                loading={Downloader.inProcess}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      ),
    },
  ];

  return (
    <>
      <Typography.Title level={2}>Warehouse category</Typography.Title>

      <Space size={16} align="start">
        <Tooltip title="Reload all infos">
          <Button
            color="primary"
            onClick={(): void => {
              reloadAll(uuid, dispatch);
            }}
            disabled={Downloader.inProcess}
          >
            Reload all <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Descriptions
        bordered
        column={1}
        size="small"
        contentStyle={{ width: '75%' }}
        items={items}
      />

      <WarehouseProductsTable />
    </>
  );
}

const mapStateToProps = ({ Downloader }: RootState) => ({ Downloader });

export default connect(mapStateToProps)(DownloaderWarehouseCategory);
