import { red } from '@ant-design/colors';
import { CloseCircleOutlined } from '@ant-design/icons';
import { type IEbayMarketplace } from '@mahawi/eshop-common/dist/src/types';
import {
  EEbayCommandType,
  EEbayListingStatus,
  EEbayOfferStatus,
} from '@mahawi/eshop-rabbit/dist/src/lib/message';
import { type RootState } from 'admin/react/reducers';
import { type IEbayState } from 'admin/react/reducers/ebay/types';
import { type IProductState } from 'admin/react/reducers/product/types';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import {
  ebayOfferClearAllResponses,
  ebayOfferDelete,
  ebayOfferPost,
  ebayOfferPublish,
  ebayOfferPut,
  ebayOffersLoad,
} from '../../../reducers/ebay/actions';
import Link from '../../link';

const { Text } = Typography;

interface DataTypeOffer {
  key: string;
  offerId: string;
  marketplaceCode: string;
  listingStatus?: string;
  listingId?: string;
  offerStatus: string;
  mpn: string;
}

const getColumnsOffer = (
  Ebay: IEbayState,
  dispatch: Dispatch,
): ColumnsType<DataTypeOffer> => [
  {
    title: 'Offer ID',
    dataIndex: 'offerId',
  },
  {
    title: 'Listing ID',
    dataIndex: 'listingId',
  },
  {
    title: 'Marketplace',
    dataIndex: 'marketplaceCode',
  },
  {
    title: 'Listing Status',
    dataIndex: 'listingStatus',
    render: (value) => (
      <Text
        strong
        type={value === EEbayListingStatus.ACTIVE ? 'success' : 'danger'}
      >
        {value}
      </Text>
    ),
  },
  {
    title: 'Offer Status',
    dataIndex: 'offerStatus',
    render: (value) => (
      <Text
        strong
        type={value === EEbayOfferStatus.PUBLISHED ? 'success' : 'danger'}
      >
        {value}
      </Text>
    ),
  },
  {
    title: 'Action',
    render: (_text, record) => (
      <Space size={16} align="start">
        <a
          target="_blank"
          href={`https://www.ebay.com/itm/${record.listingId}`}
          rel="noreferrer noopener nofollow"
        >
          <Button size="large" disabled={!record.listingId}>
            View on ebay
          </Button>
        </a>

        <a
          target="_blank"
          href={`https://www.ebay.com/sl/list?mode=ReviseItem&itemId=${record.listingId}`}
          rel="noreferrer noopener nofollow"
        >
          <Button size="large" disabled={!record.listingId}>
            Edit on ebay
          </Button>
        </a>

        <Button
          size="large"
          danger
          onClick={() => {
            if (record.mpn) {
              dispatch(ebayOfferPut(record.mpn, record.marketplaceCode));
            }
          }}
          disabled={Ebay.inProcess || !record.mpn}
        >
          Update
        </Button>

        <Button
          size="large"
          danger
          onClick={() => {
            if (record.mpn) {
              dispatch(ebayOfferPublish(record.mpn, record.marketplaceCode));
            }
          }}
          disabled={Ebay.inProcess}
        >
          Publish
        </Button>

        <Popconfirm
          title="Are you sure to delete this offer?"
          description="Offer will be deleted from eBay."
          onConfirm={() => {
            dispatch(ebayOfferDelete(record.marketplaceCode, record.offerId));
          }}
          okText="Delete"
          cancelText="Cancel"
          placement="topLeft"
        >
          <Button danger size="large" disabled={Ebay.inProcess}>
            Delete offer
          </Button>
        </Popconfirm>
      </Space>
    ),
  },
];

function EbayOffersContainer({
  dispatch,
  Product,
  Ebay,
  Marketplaces,
}: {
  dispatch: Dispatch;
  Product: IProductState;
  Ebay: IEbayState;
  Marketplaces: IEbayMarketplace[];
}): React.ReactElement {
  const marketplacesJson = JSON.stringify(Marketplaces);
  const [marketplaceElements, setMarketplaceElements] = React.useState<
    React.ReactElement[]
  >([]);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [drawerContent, setDrawerContent] =
    React.useState<React.ReactElement>();

  useEffect(() => {
    const marketplaceElementsUE = Marketplaces.map((marketplace) => {
      const productEbay = Ebay.productEbay[marketplace.code];
      const offer = Ebay.offers[marketplace.code];
      const response = Ebay.offers[marketplace.code]?.response;

      const dataSourceOffers =
        (typeof offer?.response !== 'boolean' &&
          offer?.type === EEbayCommandType.GET_OFFERS &&
          offer?.response.offers.map((o) => {
            const dataSourceOffer: DataTypeOffer = {
              key: o.offerId,
              offerId: o.offerId,
              marketplaceCode: o.marketplaceId,
              listingStatus: o.listing?.listingStatus,
              listingId: o.listing?.listingId,
              offerStatus: o.status,
              mpn: o.sku,
            };

            return dataSourceOffer;
          })) ||
        [];

      return (
        <Row key={marketplace.code}>
          <Col span={24}>
            <Divider orientation="left">
              <Space size={16} align="start">
                {marketplace.name}
                <Link
                  to={`/ebay/marketplace/${marketplace.code}`}
                  openInNewTab
                />
              </Space>
            </Divider>

            {productEbay && productEbay.listingId && (
              <Space size={16} align="start">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    if (Product.mpn) {
                      dispatch(
                        ebayOffersLoad(
                          Product.uuid,
                          Product.mpn,
                          marketplace.code,
                        ),
                      );
                    }
                  }}
                  disabled={Ebay.inProcess}
                >
                  Load
                </Button>

                <Button
                  size="large"
                  danger
                  onClick={() => {
                    if (Product.mpn) {
                      dispatch(ebayOfferPut(Product.mpn, marketplace.code));
                    }
                  }}
                  disabled={Ebay.inProcess || !Product.mpn}
                >
                  Update
                </Button>

                <Button
                  size="large"
                  danger
                  onClick={() => {
                    if (Product.mpn) {
                      dispatch(ebayOfferPublish(Product.mpn, marketplace.code));
                    }
                  }}
                  disabled={Ebay.inProcess}
                >
                  Publish
                </Button>
              </Space>
            )}

            <br />
            <br />

            {Ebay.offers[marketplace.code] && (
              <Space size={16} align="start" direction="vertical">
                {response === false && (
                  <Avatar
                    style={{ backgroundColor: red.primary }}
                    icon={<CloseCircleOutlined />}
                  />
                )}

                {response && response !== true && (
                  <>
                    <Table
                      columns={getColumnsOffer(Ebay, dispatch)}
                      dataSource={dataSourceOffers}
                    />

                    <Button
                      onClick={() => {
                        const text = (
                          <Typography.Text>
                            <small>
                              <pre>
                                {JSON.stringify(
                                  Ebay.offers[marketplace.code],
                                  null,
                                  2,
                                )}
                              </pre>
                            </small>
                          </Typography.Text>
                        );

                        if (text) {
                          setDrawerContent(text);
                        }

                        setOpenDrawer(!openDrawer);
                      }}
                    >
                      Show full JSON response
                    </Button>
                  </>
                )}
              </Space>
            )}
          </Col>
        </Row>
      );
    });

    setMarketplaceElements(marketplaceElementsUE);
  }, [
    Marketplaces,
    marketplacesJson,
    Ebay.inProcess,
    Ebay.offers,
    Ebay.productEbay,
    Product.mpn,
    Product.uuid,
    dispatch,
    openDrawer,
    Ebay,
  ]);

  return (
    <>
      <Space size={16} align="start">
        <Button
          size="large"
          onClick={() => {
            dispatch(ebayOfferClearAllResponses());
          }}
        >
          Clear all responses
        </Button>

        <Button
          size="large"
          type="primary"
          onClick={() => {
            Marketplaces.map(
              (marketplace) =>
                Product.mpn &&
                dispatch(
                  ebayOffersLoad(Product.uuid, Product.mpn, marketplace.code),
                ),
            );
          }}
          disabled={Ebay.inProcess}
        >
          Load All
        </Button>

        <Button
          size="large"
          danger
          onClick={() => {
            Marketplaces.map((marketplace) =>
              dispatch(ebayOfferPost(Product.uuid, marketplace.code)),
            );
          }}
          disabled={Ebay.inProcess}
        >
          Create All
        </Button>

        <Button
          size="large"
          danger
          onClick={() => {
            Marketplaces.forEach((marketplace) => {
              if (Product.mpn) {
                dispatch(ebayOfferPut(Product.uuid, marketplace.code));
              }
            });
          }}
          disabled={Ebay.inProcess || !Product.mpn}
        >
          Update All
        </Button>

        <Button
          size="large"
          danger
          onClick={() => {
            Marketplaces.forEach(
              (marketplace) =>
                Product.mpn &&
                dispatch(ebayOfferPublish(Product.mpn, marketplace.code)),
            );
          }}
          disabled={Ebay.inProcess || !Product.mpn}
        >
          Publish All
        </Button>
      </Space>

      {marketplaceElements.map((marketplaceElement) => marketplaceElement)}

      <br />
      <Space size={16} align="start">
        <Button
          size="large"
          onClick={() => {
            dispatch(ebayOfferClearAllResponses());
          }}
        >
          Clear all responses
        </Button>
      </Space>

      <Drawer
        width="50%"
        title="JSON response"
        placement="right"
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}

const mapStateToProps = ({ Ebay, Product }: RootState) => ({ Ebay, Product });

export default connect(mapStateToProps)(EbayOffersContainer);
