import { green, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  type IAdminCategory,
  type ILanguageType,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { type ILanguageState } from 'admin/react/reducers/language/types';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import {
  AddFocusEventListener,
  FormTextHandler,
} from '../../fragments/form-events';
import { nameLive, nameUpdate } from '../../reducers/category/actions';
import { type ICategoryState } from '../../reducers/category/types';
import GoogleTranslate from '../google-translate';
import GoogleTranslateAutomat, {
  type ITextTranslate,
} from '../google-translate-automat';

interface IForm {
  name: string;
}

const { Panel } = Collapse;

function Name({
  Language,
  Category,
  dispatch,
}: {
  Language: ILanguageState;
  Category: ICategoryState;
  dispatch: Dispatch;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  const [categoryNameEN, setCategoryNameEN] = useState<ITranslation>();
  const [gtLinkName, setGtLinkName] = useState<string>('GT name EN → ---');
  const [invalidLanguageTranslations, setInvalidLanguageTranslations] =
    useState<Set<string>>(new Set());

  const translateName = useCallback((): void => {
    AddFocusEventListener(form, (text: string): void => {
      form.setFieldValue('name', text);

      if (!Language.languageType) {
        return;
      }

      dispatch(nameLive(text, Language.languageType.code));
    });
  }, [Language.languageType?.code, dispatch, form]);

  const keyDownHandler = useCallback(
    (event: KeyboardEvent): void => {
      FormTextHandler(form, event, (text: string): void => {
        form.setFieldValue('name', text);

        if (!Language.languageType) {
          return;
        }

        dispatch(nameLive(text, Language.languageType.code));
      });
    },
    [Language.languageType?.code, dispatch, form],
  );

  const onFinish = useCallback(
    ({ name }: IForm, category: IAdminCategory | undefined): void => {
      if (category && Language.languageType) {
        dispatch(nameUpdate(category.uuid, name, Language.languageType.code));
      }
    },
    [Language.languageType?.code, dispatch],
  );

  useEffect((): void => {
    const productNameENUE: ITranslation | undefined =
      Category.category?.names.find(
        (p: ITranslation): boolean => p.code === 'EN',
      );
    setCategoryNameEN(productNameENUE);

    const categoryNameUE: string | null = getTranslation(
      Category.category?.names,
      Language.languageType,
    );

    const categoryNameTranslatedUE: string = categoryNameUE
      ? categoryNameUE
      : '';

    form.setFieldsValue({
      name: categoryNameTranslatedUE,
    });

    setGtLinkName(`GT name EN → ${Language.languageType?.code}`);
  }, [
    Language.languageType?.code,
    Category.category,
    form,
    Category.category?.names,
    Category.updatedAt,
  ]);

  useEffect(() => {
    document.addEventListener('keydown', (event: KeyboardEvent): void =>
      keyDownHandler(event),
    );

    return () => {
      document.removeEventListener('keydown', (event: KeyboardEvent): void =>
        keyDownHandler(event),
      );
    };
  }, [Language, Language.languageType?.code, dispatch, form, keyDownHandler]);

  useEffect((): void => {
    const invalidLanguageTranslationsUE = new Set<string>();

    Language.languages?.forEach((language: ILanguageType): void => {
      const cn: string | null = getTranslation(
        Category.category?.names,
        Language.languageType,
      );

      if (!cn || cn.length <= 2) {
        invalidLanguageTranslationsUE.add(language.code);
      }
    });

    setInvalidLanguageTranslations(invalidLanguageTranslationsUE);
  }, [
    Language.languages,
    Language.languageType?.code,
    Category.category?.names,
    Category.updatedAt,
  ]);

  const categoryNameTranslated: string = categoryNameEN?.value || '';

  const texts: ITextTranslate[] = [
    {
      text: categoryNameTranslated,
      callbacks: [
        (text: string, lc: string): void => {
          form.setFieldValue('name', text);

          dispatch(nameLive(text, lc));
        },
      ],
    },
  ];

  return (
    <Collapse>
      <Panel
        header={
          <Space size={8} align="start" direction="horizontal">
            <strong>Category names</strong>
            {invalidLanguageTranslations.size === 0 ? (
              <CheckCircleOutlined style={{ color: green.primary }} />
            ) : (
              <>
                <CloseCircleOutlined style={{ color: red.primary }} />
                <small>
                  {[...invalidLanguageTranslations]
                    .map((code: string): string => code)
                    .join(', ')}
                </small>
              </>
            )}
          </Space>
        }
        key="name"
      >
        <Form
          form={form}
          onFinish={(values: IForm): void | null | undefined =>
            Category.category && onFinish(values, Category.category)
          }
          layout="vertical"
        >
          <Row>
            <Col span={3}>
              <Space size={16} align="start">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={Category.inProcess}
                >
                  Submit
                </Button>
                <Spin size="small" spinning={Category.inProcess} />
              </Space>
            </Col>

            <Col span={3}>
              <GoogleTranslateAutomat
                texts={texts}
                submit={form.submit}
                isSaving={Category.inProcess}
              />
            </Col>

            <Col span={3}>
              <GoogleTranslate
                fromLanguageCode="EN"
                toLanguageCode={Language.languageType?.code}
                name={gtLinkName}
                text={categoryNameTranslated}
                onClick={(): void => translateName()}
              />
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'string',
                    min: 2,
                  },
                ]}
              >
                <Input
                  placeholder="Name"
                  disabled={Category.inProcess}
                  onBlur={(e): void => {
                    if (!Language.languageType) {
                      return;
                    }

                    dispatch(
                      nameLive(
                        e.target.value.trim(),
                        Language.languageType.code,
                      ),
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Category, Language }: RootState) => ({
  Language,
  Category,
});

export default connect(mapStateToProps)(Name);
