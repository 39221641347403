import { ReloadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popover, Row, Skeleton, Space } from 'antd';
import React, { type CSSProperties, useEffect } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import MessengerDetail from '../components/messenger/detail';
import MessengerList from '../components/messenger/list';
import MessengerMessages from '../components/messenger/messages';
import { type RootState } from '../reducers';
import {
  adminConnected,
  adminDisconnected,
  loadLeads,
} from '../reducers/messenger/actions';
import { type IMessengerState } from '../reducers/messenger/types';
import { type IUserState } from '../reducers/user/types';
import Socket from '../socket';

const styleMessengerTab: CSSProperties = {
  height: 'calc(100vh - 9.3rem)',
  padding: '0 0.25rem',
  overflowY: 'scroll',
};

function MessengerContainer({
  dispatch,
  Messenger,
  User,
  socket = undefined,
}: {
  dispatch: Dispatch;
  Messenger: IMessengerState;
  User: IUserState;
  socket?: Socket;
}): React.ReactElement {
  const [hours, setHours] = React.useState<number>(1);

  useEffect((): (() => void) => {
    const adminConnectedInterval: NodeJS.Timeout = setInterval(
      () => User.uuid && dispatch(adminConnected(User.uuid)),
      5000,
    );

    return (): void => {
      clearInterval(adminConnectedInterval);
      if (User.uuid) {
        dispatch(adminDisconnected(User.uuid));
      }
    };
  }, [dispatch, User.uuid, hours]);

  useEffect((): void => {
    const audio = new Audio(`/static/juntos.mp3`);

    if (audio) {
      audio.play();
    }
  }, [Messenger.lastMessage.uuid]);

  useEffect(() => {
    if (socket) {
      socket.subscribeEventMessenger();
    }

    return (): void => {
      if (socket) {
        socket.unsubscribeEventMessenger();
      }
    };
  }, [socket]);

  return (
    <Row gutter={0}>
      <Col span={6} style={styleMessengerTab}>
        <Space size={16} align="start">
          {[1, 2, 6, 12].map(
            (interval: number): React.ReactElement => (
              <Popover
                trigger="hover"
                key={interval}
                title={`Reload leads ${interval} h. before.`}
                placement="top"
              >
                <Button
                  size="small"
                  type={hours === interval ? 'primary' : 'default'}
                  onClick={(): void => {
                    setHours(interval);
                  }}
                >
                  {interval} h.
                </Button>
              </Popover>
            ),
          )}
          <Button
            type="primary"
            size="small"
            onClick={(): void => {
              dispatch(loadLeads(hours));
            }}
          >
            Reload <ReloadOutlined />
          </Button>
        </Space>

        <Divider />

        {!Messenger.leads && <Skeleton />}
        {Messenger.leads && <MessengerList />}
      </Col>

      <Col span={6} style={styleMessengerTab}>
        <MessengerMessages />
      </Col>

      <Col span={12} style={styleMessengerTab}>
        <MessengerDetail />
      </Col>
    </Row>
  );
}

const mapStateToProps = ({
  Messenger,
  User,
}: RootState): {
  Messenger: IMessengerState;
  User: IUserState;
} => ({
  Messenger,
  User,
});

export default connect(mapStateToProps)(MessengerContainer);
