import { blue, green } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';
import { type IMessage } from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { type IMessengerState } from 'admin/react/reducers/messenger/types';
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  List,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { sendMessage } from '../../reducers/messenger/actions';

interface IForm {
  message: string;
}

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

function MessengerMessages({
  dispatch,
  Messenger,
}: {
  dispatch: Dispatch;
  Messenger: IMessengerState;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  const [messages, setMessages] = useState<IMessage[]>([]);

  const onFinish = useCallback(
    ({ message }: IForm, leadUUID: string | undefined): void => {
      if (!leadUUID || !message) {
        return;
      }

      dispatch(sendMessage(leadUUID, message));

      form.setFieldsValue({
        message: '',
      });
    },
    [dispatch, form],
  );

  useEffect(() => {
    setMessages(Messenger.messages);
  }, [Messenger.messages, Messenger.updatedAt]);

  if (Messenger.inProcess) {
    return <Skeleton active />;
  }

  if (!Messenger.lead.uuid) {
    return <Typography>Select lead from list!</Typography>;
  }

  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          onFinish={(values: IForm): void =>
            onFinish(values, Messenger.lead.uuid)
          }
        >
          <Row gutter={0}>
            <Col span={19}>
              <Form.Item
                name="message"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Message" disabled={Messenger.inProcess} />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={Messenger.inProcess}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col span={24}>
        <List
          className="comment-list"
          itemLayout="horizontal"
          header={`${messages.length} replies`}
          dataSource={messages}
          renderItem={(item: IMessage): React.ReactElement => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    size={40}
                    icon={<UserOutlined />}
                    style={{
                      backgroundColor:
                        item.direction === 'in' ? green.primary : blue.primary,
                    }}
                  />
                }
                title={dayjs(item.timestamp * 1000).format(DATE_FORMAT)}
                description={item.message}
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
}

const mapStateToProps = ({ Messenger }: RootState) => ({ Messenger });

export default connect(mapStateToProps)(MessengerMessages);
