import React from 'react';

import ExternalLink from './external-link';

function GoogleTranslate({
  fromLanguageCode,
  name,
  onClick = undefined,
  text = undefined,
  toLanguageCode,
}: {
  fromLanguageCode?: string;
  name: string;
  onClick?: () => void;
  text?: string;
  toLanguageCode?: string;
}): React.ReactElement {
  if (
    !text ||
    fromLanguageCode === toLanguageCode ||
    !toLanguageCode ||
    !fromLanguageCode
  ) {
    return <span>{name}</span>;
  }

  const gtLink = new URL('https://translate.google.com/');
  gtLink.searchParams.append('sl', fromLanguageCode.toLowerCase());
  gtLink.searchParams.append('tl', toLanguageCode.toLowerCase());
  gtLink.searchParams.append('text', text.trim());
  gtLink.searchParams.append('op', 'translate');

  return (
    <ExternalLink
      to={gtLink.toString()}
      label={name}
      ariaLabel="Google Translate"
      onClick={onClick}
    />
  );
}

export default GoogleTranslate;
